import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'relay-hooks';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { ArticleList_allPosts$key } from 'components/views/article/__generated__/ArticleList_allPosts.graphql';
import PostDeleteMutation from 'mutations/post/PostDeleteMutation';
import { URL_NAMES } from 'projectConstants';
import MaterialTable from 'components/table/MaterialTable';

const fragmentSpec = graphql`
    fragment ArticleList_allPosts on PostNodeConnection {
        totalCount,
        edges {
            node {
                id,
                title,
                category
            }
        }
    }
`;

const header = [
    { id: 'node.title', label: 'Title' },
    { id: 'node.category', label: 'Category', lookup: { 'workout': 'Workout', 'nutrition': 'Meal', 'challenge': 'Challenge' } },
];

interface ArticleListProps {
    allPosts: ArticleList_allPosts$key;
    relay: { retry: () => void | undefined };
}

export default function (props: ArticleListProps) {
    const { relay: { retry }, allPosts } = props;
    const { edges, totalCount } = useFragment(fragmentSpec, allPosts);
    const { enqueueSnackbar } = useSnackbar();

    const onDelete = async (ids: string[]) => {
        const response = await PostDeleteMutation.commit(ids);
        if (response?.deletePosts?.errors) {
            enqueueSnackbar('Could not successfully delete all selected elements', {variant: 'error'});
        } else {
            enqueueSnackbar('Successfully deleted', {variant: 'success'});
        }
        retry();
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <MaterialTable
                    header={header}
                    data={edges}
                    idField='node.id'
                    totalCount={totalCount}
                    title='Articles'
                    deleteClickHandler={(ids, confirmed) => confirmed ? onDelete(ids) : null}
                    addUrlName={URL_NAMES.ARTICLE.CREATE}
                    editUrlName={URL_NAMES.ARTICLE.UPDATE}
                />
            </Grid>
        </Grid>
    );
}
