import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import { PostCreateUpdateMutation, PostCreateUpdateInput } from 'mutations/post/__generated__/PostCreateUpdateMutation.graphql';

const mutation = graphql`
    mutation PostCreateUpdateMutation($input: PostCreateUpdateInput!) {
        createUpdatePost(input: $input) {
            id,
            errors {
                field,
                messages
            }
        }
    }
`;

function commit(input: PostCreateUpdateInput) {
    return commitMutation<PostCreateUpdateMutation>(
        environment,
        {
            mutation,
            variables: {
                input
            },
        }
    );
}

export default {commit};