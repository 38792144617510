import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import { LoginUserMutation } from 'mutations/auth/__generated__/LoginUserMutation.graphql';

const mutation = graphql`
    mutation LoginUserMutation($input: ObtainJSONWebTokenInput!) {
        tokenAuth(input: $input) {
            token,
            refreshExpiresIn,
            errors {
                field,
                messages
            }
        }
    }
`;

function commit(
    email: string,
    password: string,
) {
    return commitMutation<LoginUserMutation>(
        environment,
        {
            mutation,
            variables: {
                input: {
                    email: email,
                    password: password,
                },
            },
        }
    );
}

export default {commit};