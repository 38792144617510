import React from 'react';
import _ from 'lodash';
import TextField, { StandardTextFieldProps, FilledTextFieldProps, OutlinedTextFieldProps } from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

type choices = {[key: string]: string};

interface StandardSelectFieldProps extends StandardTextFieldProps {
    choices: choices
}

interface FilledSelectFieldProps extends FilledTextFieldProps {
    choices: choices
}

interface OutlinedSelectFieldProps extends OutlinedTextFieldProps {
    choices: choices
}

type SelectFieldProps = StandardSelectFieldProps | FilledSelectFieldProps | OutlinedSelectFieldProps;

export default function SelectField({choices, ...props}: SelectFieldProps) {
    return (
        <TextField select {...props} >
            {_.map(choices, (value, key) => (
                <MenuItem key={key} value={key}>
                    {value}
                </MenuItem>
            ))}
        </TextField>
    );
}