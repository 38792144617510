import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import { ConfirmAccountMutation } from 'mutations/auth/__generated__/ConfirmAccountMutation.graphql';

const mutation = graphql`
    mutation ConfirmAccountMutation($input: VerifyAccountInput!) {
        verifyEmail(input: $input) {
            errors {
                field,
                messages
            }
        }
    }
`;

function commit(
    emailToken: string,
) {
    return commitMutation<ConfirmAccountMutation>(
        environment,
        {
            mutation,
            variables: {
                input: {
                    emailToken,
                },
            },
        }
    );
}

export default {commit};