import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton/IconButton';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        position: 'relative'
    },
    deleteIcon: {
        position: 'absolute',
        top: '-24px',
        right: '-24px'
    },
    preview: {
        maxHeight: '128px',
        width: 'auto',
    },
    layer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    }
}));

interface Props {
    element: { [key: string]: any };
    previewElement: JSX.Element;
    onDelete?: (element: { [key: string]: any }) => void;
}

export default function ({ element, previewElement, onDelete, ...props }: Props) {
    const classes = useStyles();
    return <div className={classes.container}>
        {onDelete
            ? <IconButton className={classes.deleteIcon} onClick={() => onDelete(element)}>
                <DeleteForeverIcon />
            </IconButton>
            : null}
        {/* HACK: extra layer on-top allows dragging videos, without it didn't work for videos */}
        <div className={classes.layer} />
        {React.cloneElement(previewElement, { className: classes.preview })}
    </div>
}