/* tslint:disable */
/* eslint-disable */
/* @relayHash c70f60c30e329c24cdadff6979f1f37c */

import { ConcreteRequest } from "relay-runtime";
export type SendActivationMailInput = {
    email: string;
    clientMutationId?: string | null;
};
export type SendActivationMailMutationVariables = {
    input: SendActivationMailInput;
};
export type SendActivationMailMutationResponse = {
    readonly sendActivationMail: {
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        } | null> | null;
    } | null;
};
export type SendActivationMailMutation = {
    readonly response: SendActivationMailMutationResponse;
    readonly variables: SendActivationMailMutationVariables;
};



/*
mutation SendActivationMailMutation(
  $input: SendActivationMailInput!
) {
  sendActivationMail(input: $input) {
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SendActivationMailInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "sendActivationMail",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendActivationMailPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SendActivationMailMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SendActivationMailMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SendActivationMailMutation",
    "id": null,
    "text": "mutation SendActivationMailMutation(\n  $input: SendActivationMailInput!\n) {\n  sendActivationMail(input: $input) {\n    errors {\n      field\n      messages\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'e3f7ccf8668ebc2672455d8222f36a64';
export default node;
