import React from 'react';
import PreviewElement from './PreviewElement';

interface Props {
    element: { [key: string]: any };
    onDelete?: (element: { [key: string]: any }) => void;
}

export default function ({ element, onDelete }: Props) {
    return <PreviewElement
        element={element}
        onDelete={onDelete}
        previewElement={<video controls={false} src={`${element.preview}#t=0.5`} preload='metadata' poster={require('assets/video-placeholder.png')} />}
    />
}