import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import { AppBar, Toolbar, IconButton, Typography, makeStyles, Theme } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ProfileMenu from 'components/dashboard/ProfileMenu';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
}));

export default function (props: { open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
  const classes = useStyles();
  const { open, setOpen } = props;
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const accountButtonRef = useRef(null);

  return (
    <AppBar position='absolute' className={clsx(classes.appBar, open && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge='start'
          color='inherit'
          aria-label='open drawer'
          onClick={() => setOpen(true)}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
          Dashboard
          </Typography>
        <IconButton
          edge='end'
          color='inherit'
          onClick={() => setProfileMenuOpen(true)}
          ref={accountButtonRef}
        >
          <AccountCircle />
        </IconButton>
      </Toolbar>
      <ProfileMenu
        anchorElement={accountButtonRef.current}
        open={profileMenuOpen}
        setOpen={setProfileMenuOpen}
      />
    </AppBar>
  );
}
