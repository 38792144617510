import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import { TagCreateUpdateMutation, TagCreateUpdateInput } from 'mutations/tag/__generated__/TagCreateUpdateMutation.graphql';

const mutation = graphql`
    mutation TagCreateUpdateMutation($input: TagCreateUpdateInput!) {
        createUpdateTag(input: $input) {
            id,
            errors {
                field,
                messages
            }
        }
    }
`;

function commit(input: TagCreateUpdateInput) {
    return commitMutation<TagCreateUpdateMutation>(
        environment,
        {
            mutation,
            variables: {
                input
            },
        }
    );
}

export default {commit};