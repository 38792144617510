/* tslint:disable */
/* eslint-disable */
/* @relayHash 6199c9865db0d7878491b0da017d508b */

import { ConcreteRequest } from "relay-runtime";
export type ImageDeleteMutationVariables = {
    ids: Array<string | null>;
};
export type ImageDeleteMutationResponse = {
    readonly deleteImages: {
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        } | null> | null;
    } | null;
};
export type ImageDeleteMutation = {
    readonly response: ImageDeleteMutationResponse;
    readonly variables: ImageDeleteMutationVariables;
};



/*
mutation ImageDeleteMutation(
  $ids: [ID]!
) {
  deleteImages(ids: $ids) {
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ids",
    "type": "[ID]!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteImages",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "ImageDelete",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ImageDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ImageDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ImageDeleteMutation",
    "id": null,
    "text": "mutation ImageDeleteMutation(\n  $ids: [ID]!\n) {\n  deleteImages(ids: $ids) {\n    errors {\n      field\n      messages\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '8310caf4902c09827cbf95dc5e0bb40a';
export default node;
