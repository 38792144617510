import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import { SendActivationMailMutation } from 'mutations/auth/__generated__/SendActivationMailMutation.graphql';

const mutation = graphql`
    mutation SendActivationMailMutation($input: SendActivationMailInput!) {
        sendActivationMail(input: $input) {
            errors {
                field,
                messages
            }
        }
    }
`;

function commit(
    email: string,
) {
    return commitMutation<SendActivationMailMutation>(
        environment,
        {
            mutation,
            variables: {
                input: {
                    email,
                },
            },
        }
    );
}

export default {commit};