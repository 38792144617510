import React, { SetStateAction } from 'react';
import clsx from 'clsx';
import { Drawer, Divider, List, makeStyles, Theme, Hidden } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { Link as FoundLink } from 'found';
import { URL_NAMES } from 'projectConstants';
import { isMobile  } from "react-device-detect";

const drawerWidth = 240;

const mainListItems =  (
  <div>
    <FoundLink as={ListItem} to={URL_NAMES.HOME} activePropName='selected' button>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary='Dashboard' />
    </FoundLink>
    <FoundLink as={ListItem} to={URL_NAMES.PROFILE} activePropName='selected' button>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary='Profile' />
    </FoundLink>
    <FoundLink as={ListItem} to={URL_NAMES.TAG.LIST} activePropName='selected' button>
      <ListItemIcon>
        <LocalOfferIcon />
      </ListItemIcon>
      <ListItemText primary='Tags' />
    </FoundLink>
    <FoundLink as={ListItem} to={URL_NAMES.ARTICLE.LIST} activePropName='selected' button>
      <ListItemIcon>
        <DescriptionIcon />
      </ListItemIcon>
      <ListItemText primary='Articles' />
    </FoundLink>
    <FoundLink as={ListItem} to={URL_NAMES.VIDEO.LIST} activePropName='selected' button>
      <ListItemIcon>
        <OndemandVideoIcon />
      </ListItemIcon>
      <ListItemText primary='Videos' />
    </FoundLink>
  </div>
);

const useStyles = makeStyles((theme: Theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
}));

export default function (props: { open: boolean, setOpen: React.Dispatch<SetStateAction<boolean>> }) {
  const classes = useStyles();
  const { open, setOpen } = props;

  const closeDrawer = () => {
    if(isMobile) {
      setOpen(false);
    }
  }
  return (
    <>
     {isMobile && <Hidden smUp implementation="css">
    <Drawer
      variant='temporary'
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={() => setOpen(false)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List onClick={closeDrawer}>{mainListItems}</List>
    </Drawer>
    </Hidden>}
    <Hidden xsDown implementation="css">
    <Drawer
      variant='permanent'
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={() => setOpen(false)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>{mainListItems}</List>
    </Drawer>
    </Hidden>
    </>
  );
}
