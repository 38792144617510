import React, { useEffect } from 'react';
import { useRouter } from 'found';

export default function (props: {children?: JSX.Element, redirectUrl: string, condition: () => boolean}): JSX.Element {
    const {children, redirectUrl, condition} = props;
    const {router} = useRouter();

    useEffect(() => {
        if (condition()) router.push(redirectUrl);
    })

    if (condition() || children === undefined) return <></>;
    return children;
}
