/* tslint:disable */
/* eslint-disable */
/* @relayHash ee22150e39a70bdd9740a9ffc70a4429 */

import { ConcreteRequest } from "relay-runtime";
export type StripeConnectInput = {
    clientMutationId?: string | null;
};
export type ConnectMutationVariables = {
    input: StripeConnectInput;
};
export type ConnectMutationResponse = {
    readonly stripeConnect: {
        readonly redirectUrl: string | null;
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        } | null> | null;
    } | null;
};
export type ConnectMutation = {
    readonly response: ConnectMutationResponse;
    readonly variables: ConnectMutationVariables;
};



/*
mutation ConnectMutation(
  $input: StripeConnectInput!
) {
  stripeConnect(input: $input) {
    redirectUrl
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "StripeConnectInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "stripeConnect",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "StripeConnectPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "redirectUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ConnectMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ConnectMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ConnectMutation",
    "id": null,
    "text": "mutation ConnectMutation(\n  $input: StripeConnectInput!\n) {\n  stripeConnect(input: $input) {\n    redirectUrl\n    errors {\n      field\n      messages\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '350d63116b1a650126702df15946952e';
export default node;
