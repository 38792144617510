import React from 'react';
import Copyright from 'components/common/Copyright';
import BarAndDrawer from 'components/dashboard/BarAndDrawer';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import Container from '@material-ui/core/Container/Container';
import Box from '@material-ui/core/Box/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  }
}));

export default function Dashboard(props: { children: React.ReactNode }): JSX.Element {
  const classes = useStyles();
  const { children } = props;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <BarAndDrawer />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='xl' className={classes.container}>
          {children}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
