import {
    MiddlewareNextFn,
    RelayRequestAny
} from 'react-relay-network-modern/es';
import {REFRESH_EXP_KEY, TOKEN_EXP_KEY, URL_NAMES, NO_AUTH_RELAY_ACTIONS} from 'projectConstants';
import RefreshTokenMutation from 'mutations/auth/RefreshTokenMutation';
import hasExpired from 'utils/hasExpired';
import setTokenExp from 'utils/setTokenExp';
import routerUtils from 'utils/routerUtils';
import removeTokenExp from 'utils/removeTokenExp';

function redirectToLoginAndRemoveExp() {
    removeTokenExp();
    routerUtils.push(URL_NAMES.AUTH.LOGIN);
}

export default function authMiddleware() {
    return (next: MiddlewareNextFn) => async (req: RelayRequestAny) => {
        // in case we login or refresh, skip auth middleware
        if (NO_AUTH_RELAY_ACTIONS.includes(req.getID())) return await next(req);
        const jwtTokenExpiresIn = localStorage.getItem(TOKEN_EXP_KEY);
        const jwtRefreshTokenExpiresIn = localStorage.getItem(REFRESH_EXP_KEY);
        let jwtTokenHasExpired = true;
        let jwtRefreshTokenHasExpired = true;
        if (jwtTokenExpiresIn) {
            jwtTokenHasExpired = hasExpired(parseInt(jwtTokenExpiresIn));
        }
        if (jwtRefreshTokenExpiresIn) {
            jwtRefreshTokenHasExpired = hasExpired(parseInt(jwtRefreshTokenExpiresIn));
        }
        if (jwtTokenHasExpired && jwtRefreshTokenHasExpired) {
            // redirect to login, because cant refresh
            redirectToLoginAndRemoveExp();
        } else if (jwtTokenHasExpired && !jwtRefreshTokenHasExpired) {
            // refresh, and if it doesn't work redirect to login
            const refreshTokenResponse = await RefreshTokenMutation.commit()
                .catch(e => {
                    redirectToLoginAndRemoveExp();
                });
            if (refreshTokenResponse) setTokenExp(refreshTokenResponse);
        }

        let res = await next(req);

        if (res.errors) {
            // refresh, and if it doesn't work redirect to login
            const refreshTokenResponse = await RefreshTokenMutation.commit()
                .catch(e => {
                    redirectToLoginAndRemoveExp();
                });
            if (refreshTokenResponse) setTokenExp(refreshTokenResponse);
            // retry
            res = await next(req);
        }

        return res;
    };
}