import TextField from '@material-ui/core/TextField/TextField';
import React from 'react';
import CurrencyInput from "react-currency-input-field";


interface CurrencyInputComponentProps {
    [key: string]: any;
}

function CurrencyInputComponent ({ onChange, inputRef, currency, ...props }: CurrencyInputComponentProps) {
    return (
      <CurrencyInput
        id="test"
        {...props}
        intlConfig={{
          locale: "en-US",
          currency,
        }}
        onValueChange={(value, name) =>
          onChange({
            target: { value, name }
          })
        }
      />
    );
};

interface Props {
    currency: string;
    [key: string]: any;
}

export default function CurrencyField ({currency, value, ...props}: Props) {
    return <TextField 
        value={value} 
        {...props} 
        InputProps={{inputComponent: CurrencyInputComponent, inputProps:{currency}}} 
        InputLabelProps={{shrink: !!value}} 
    />
}