/* tslint:disable */
/* eslint-disable */
/* @relayHash 777f130505696eca4e96b134d3b1cfa2 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type VideoListQueryVariables = {
    first?: number | null;
    last?: number | null;
    before?: string | null;
    after?: string | null;
    orderBy?: string | null;
};
export type VideoListQueryResponse = {
    readonly allPosts: {
        readonly " $fragmentRefs": FragmentRefs<"VideoList_allPosts">;
    } | null;
};
export type VideoListQuery = {
    readonly response: VideoListQueryResponse;
    readonly variables: VideoListQueryVariables;
};



/*
query VideoListQuery(
  $first: Int
  $last: Int
  $before: String
  $after: String
  $orderBy: String
) {
  allPosts(first: $first, last: $last, before: $before, after: $after, orderBy: $orderBy, postType: "video") {
    ...VideoList_allPosts
  }
}

fragment VideoList_allPosts on PostNodeConnection {
  totalCount
  edges {
    node {
      id
      title
      category
      video {
        id
        conversionStatus
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "last",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "before",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Literal",
    "name": "postType",
    "value": "video"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VideoListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allPosts",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PostNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "VideoList_allPosts",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VideoListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allPosts",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PostNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "PostNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "PostNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "category",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "video",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "VideoNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "conversionStatus",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "VideoListQuery",
    "id": null,
    "text": "query VideoListQuery(\n  $first: Int\n  $last: Int\n  $before: String\n  $after: String\n  $orderBy: String\n) {\n  allPosts(first: $first, last: $last, before: $before, after: $after, orderBy: $orderBy, postType: \"video\") {\n    ...VideoList_allPosts\n  }\n}\n\nfragment VideoList_allPosts on PostNodeConnection {\n  totalCount\n  edges {\n    node {\n      id\n      title\n      category\n      video {\n        id\n        conversionStatus\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '9eef4166fa869313782ac86681be9f75';
export default node;
