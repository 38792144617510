import React from 'react';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'found';

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
    }),
);

interface MaterialTableToolbarProps {
    selected: any[];
    title: string;
    deleteClickHandler?: () => void;
    addUrlName?: string;
    editUrlName?: string;
}

export default (props: MaterialTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { selected, title, deleteClickHandler, addUrlName, editUrlName } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: selected.length > 0,
            })}
        >
            {selected.length > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {selected.length} selected
                </Typography>
            ) : (
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        {title}
                    </Typography>
                )}
            {addUrlName ? (
                <Tooltip title="Create">
                    <Link to={addUrlName}>
                        <IconButton aria-label="create">
                            <AddIcon />
                        </IconButton>
                    </Link>
                </Tooltip>
            ) : null}
            {selected.length === 1 && editUrlName ? (
                <Tooltip title="Edit">
                <Link
                // @ts-ignore found-named-routes overrides this
                to={{ name: editUrlName, params: { id: selected[0] } }}
                onClick={event => event.stopPropagation()}>
                    <IconButton aria-label="edit">
                        <EditIcon />
                    </IconButton>
                </Link>
            </Tooltip>
            ) : null}
            {selected.length > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete" onClick={deleteClickHandler}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : null}
        </Toolbar>
    );
};