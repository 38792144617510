import React from 'react';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FormikInstance } from 'utils/formikUtils';
import 'components/common/styles/editor.css';

export default ({ formik, fieldName }: { formik: FormikInstance, fieldName: string }) => {
    const value = _.get(formik.values, fieldName);
    return (
        <ReactQuill
            theme='snow'
            modules={{
                clipboard: {
                    matchVisual: false,
                },
            }}
            value={value}
            onChange={value => {
                formik.setFieldValue(fieldName, value);
            }}
        />
    )
}