import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export type Order = 'asc' | 'desc';

export type Lookup = { [key: string]: string, [key: number]: string } | ((value: any) => JSX.Element);

export interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    lookup?: Lookup;
    sortable?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

interface MaterialTableHeadProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    header: HeadCell[]
}

export default function (props: MaterialTableHeadProps) {
    const classes = useStyles();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, header } = props;
    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {header.map((headCell) => {
                    headCell.sortable = headCell.sortable === undefined ? true : headCell.sortable;
                    const [headCellId] = headCell.id.split('.').slice(-1);
                    if (headCell.sortable) {
                        return <TableCell
                            key={headCellId}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCellId ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCellId}
                                direction={orderBy === headCellId ? order : 'asc'}
                                onClick={createSortHandler(headCellId)}
                            >
                                {headCell.label}
                                {orderBy === headCellId ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    } else {
                        return <TableCell key={headCellId}>{headCell.label}</TableCell>
                    }

                })}
            </TableRow>
        </TableHead>
    );
}