import React from 'react';
import MaterialChip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from '@material-ui/core';

interface Props {
    color: PropTypes.Color;
    variant?: 'default' | 'outlined';
    label: string | undefined;
}
   
const Chip = (props: Props) => {
    const StyledChip = withStyles({
        root: {
            'color': 'white',
            'backgroundColor': `${props.color} !important`,
            '&:hover': {
                backgroundColor: props.color,
                filter: 'brightness(120%)',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: props.color,
                borderColor: props.color,
            },
        },
        outlined: {
            color: props.color,
            border: `1px solid ${props.color}`,
            backgroundColor: `transparent !important`,
        },
        icon: {
            color: props.variant === 'outlined' ? props.color : 'white',
        },
        deleteIcon: {
            color: props.variant === 'outlined' ? props.color : 'white',
        },
    })(MaterialChip);

    return <StyledChip {...props} />;
};


export default Chip;