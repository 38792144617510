import { useEffect } from 'react';
import { useRouter } from 'found';
import ConnectMutation from 'mutations/stripe/ConnectMutation';
import { useSnackbar } from 'notistack';
import { URL_NAMES } from 'projectConstants';

export default function () {
    const { router } = useRouter();
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        async function stripeConnect() {
            const response = await ConnectMutation.commit({});
            if (response?.stripeConnect?.errors) {
                enqueueSnackbar(response.stripeConnect.errors.toString(), { variant: 'error' });
                router.push(URL_NAMES.HOME);
            } else if (!response.stripeConnect || !response.stripeConnect.redirectUrl) {
                enqueueSnackbar('Unhandled error', { variant: 'error' });
                router.push(URL_NAMES.HOME)
            } else {
                window.location.replace(response.stripeConnect.redirectUrl);
            }
        }
        stripeConnect()
    }, [router, enqueueSnackbar])
    return null;
}