import React, { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { useTheme } from '@material-ui/core/styles';

const baseStyle: React.CSSProperties = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 4,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle: React.CSSProperties = {
    borderColor: '#2196f3'
};

const acceptStyle: React.CSSProperties = {
    borderColor: '#00e676'
};

const rejectStyle: React.CSSProperties = {
    borderColor: '#ff1744'
};

interface Props {
    value: any;
    setValue: (value: any) => void;
    accept: string;
    maxSize?: number;
    title?: string;
    maxFiles?: number | undefined;
    error?: boolean;
    errorText?: React.ReactNode;
}

export default function ({ value, setValue, accept, title, maxSize, maxFiles=1, error=false, errorText }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept,
        maxSize,
        maxFiles,
        multiple: maxFiles === 1 ? false : true,
        onDrop: acceptedFiles => {
            const acceptedUploadFiles = acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }));
            if (maxFiles === 1){
                setValue(acceptedUploadFiles)
            } else {
                setValue(
                    [...value, ...acceptedUploadFiles]
                );
            }
        },
        onDropRejected: rejectedFiles => {
            rejectedFiles.forEach(file => file.errors.forEach(error => enqueueSnackbar(error.message, { variant: 'error' })))
        }
    });
    const theme = useTheme();

    const errorStyle: React.CSSProperties = error ? {
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderWidth: 1,
    } : {}

    const errorTextStyle: React.CSSProperties = {
        marginLeft: 14,
        marginRight: 14,
        color: theme.palette.primary.main,
        marginTop: 3,
        ...theme.typography.caption
    }

    const style = useMemo(() => ({
        ...baseStyle,
        ...errorStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept,
        errorStyle
    ]);

    return (
        <>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>{title ? title : 'Drag \'n\' drop some files here, or click to select files.'}</p>
            </div>
            {error ? <p style={errorTextStyle}>{errorText}</p> : null}
        </>
    );
}