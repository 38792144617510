import React from 'react';
import FormikInputField from 'components/fields/FormikInputField';
import TextField from '@material-ui/core/TextField/TextField';
import { FormikInstance } from 'utils/formikUtils';

const NON_DIGIT = /[^0-9]/g;

interface Props {
    formik: FormikInstance;
    fieldName: string;
    [key: string]: any;
}

export default function ({formik, fieldName, ...props}: Props) {
    return <FormikInputField
        Component={TextField}
        formik={formik}
        fieldName={fieldName}
        onChange={(event: React.ChangeEvent<any>) => {
            // allows only integer values
            const { value } = event.target;
            const newValue = value.toString().replace(NON_DIGIT, '');
            formik.setFieldValue(fieldName, newValue);
        }}
        type='tel'
        {...props}
    />
}