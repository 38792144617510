import React from 'react';
import { DragDropContext, Droppable, DropResult, Draggable } from 'react-beautiful-dnd';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const grid = 8;

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        padding: grid,
        overflow: 'auto',
        minWidth: '100%'
    },
    item: {
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 ${grid}px 0 0`,
    },
}));


const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

interface Props {
    list: any[];
    setList: (list: any[]) => void;
    deletedElements?: any[];
    setDeletedElements?: (list: any[]) => void;
    ElementComponent: React.ComponentType<any>;
    droppableId: string;
}

export default function ({ list, setList, ElementComponent, deletedElements, setDeletedElements, droppableId }: Props) {
    const classes = useStyles();

    function onDelete(element: { [key: string]: any }) {
        if (deletedElements && setDeletedElements && element.id !== undefined) {
            setDeletedElements([...deletedElements, element]);
        }
        setList(list.filter(e => e.preview !== element.preview));
    }

    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        setList(
            reorder(
                list,
                result.source.index,
                result.destination.index
            )
        );
    }
    return (list.length > 0
        ? (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={droppableId} direction='horizontal'>
                    {provided => (
                        <div className={classes.container} ref={provided.innerRef} {...provided.droppableProps}>
                            {list.map((element, index) => {
                                return <Draggable key={index} draggableId={index.toString()} index={index}>
                                    {provided => (
                                        <div
                                            className={classes.item}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <ElementComponent
                                                element={element}
                                                onDelete={onDelete}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
        : null);
}