import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'relay-hooks';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { VideoList_allPosts$key } from 'components/views/video/__generated__/VideoList_allPosts.graphql';
import PostDeleteMutation from 'mutations/post/PostDeleteMutation';
import { URL_NAMES } from 'projectConstants';
import MaterialTable from 'components/table/MaterialTable';
import { conversionStatusToDisplay, generatePillColor } from './utils';
import Chip from 'components/common/Chip';

const fragmentSpec = graphql`
    fragment VideoList_allPosts on PostNodeConnection {
        totalCount,
        edges {
            node {
                id,
                title,
                category,
                video {
                    id
                    conversionStatus
                }
            }
        }
    }
`;

const header = [
    { id: 'node.title', label: 'Title' },
    { id: 'node.video.conversionStatus', label: 'Status', lookup: (value: string) => <Chip color={generatePillColor(value)} label={conversionStatusToDisplay(value)} />},
    { id: 'node.category', label: 'Category', lookup: {
        'workout': 'Workout',
        'nutrition': 'Meal',
        'challenge': 'Challenge'
    } },
];

interface VideoListProps {
    allPosts: VideoList_allPosts$key;
    relay: { retry: () => void | undefined };
}

export default function (props: VideoListProps) {
    const { relay: { retry }, allPosts } = props;
    const { edges, totalCount } = useFragment(fragmentSpec, allPosts);
    const { enqueueSnackbar } = useSnackbar();

    const onDelete = async (ids: string[]) => {
        const response = await PostDeleteMutation.commit(ids);
        if (response?.deletePosts?.errors) {
            enqueueSnackbar('Could not successfully delete all selected elements', {variant: 'error'});
        } else {
            enqueueSnackbar('Successfully deleted', {variant: 'success'});
        }
        retry();
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <MaterialTable
                    header={header}
                    data={edges}
                    idField='node.id'
                    totalCount={totalCount}
                    title='Videos'
                    deleteClickHandler={(ids, confirmed) => confirmed ? onDelete(ids) : null}
                    addUrlName={URL_NAMES.VIDEO.CREATE}
                    editUrlName={URL_NAMES.VIDEO.UPDATE}
                />
            </Grid>
        </Grid>
    );
}
