import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import { VideoCreateUpdateMutation, VideoCreateUpdateInput } from 'mutations/video/__generated__/VideoCreateUpdateMutation.graphql';

const mutation = graphql`
    mutation VideoCreateUpdateMutation($input: VideoCreateUpdateInput!) {
        createUpdateVideo(input: $input) {
            id,
            errors {
                field,
                messages
            }
        }
    }
`;

function commit(input: VideoCreateUpdateInput) {
    return commitMutation<VideoCreateUpdateMutation>(
        environment,
        {
            mutation,
            variables: {
                input
            },
        }
    );
}

export default {commit};