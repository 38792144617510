import React from 'react';
import _ from 'lodash';
import { Route } from 'found';
import { pageAndPageSizeToQuery, orderAndOrderByToQuery } from 'components/table/MaterialTable';
import { INITIAL_PAGE, INITIAL_PAGE_SIZE, INITIAL_ORDER, INITIAL_ORDER_BY, LIST_FETCH_POLICY } from 'projectConstants';
import { Environment } from 'react-relay';

interface RenderArgs {
    error: any;
    props: {[key: string]: any};
    retry: () => void;
    Component: React.ComponentType<any> | null | undefined;
    environment: Environment;
    variables: {[key: string]: any};
}

export default class ListRoute extends Route {
    constructor(props: { [key: string]: any }) {
        const alternateQuery = {
            ...pageAndPageSizeToQuery(INITIAL_PAGE, INITIAL_PAGE_SIZE),
            ...orderAndOrderByToQuery(INITIAL_ORDER, INITIAL_ORDER_BY)
        }
        props.prepareVariables = (_params: any, { location }: any) => _.isEmpty(location.query) ? alternateQuery : location.query;
        props.fetchPolicy = LIST_FETCH_POLICY
        props.render = ({ error, props, retry, Component, environment, variables }: RenderArgs) => {
            return (!Component || !props) ? null : <Component
                relay={{ error, retry, environment, variables }} 
                {...props}
            />
        };
        super(props);
    }
}