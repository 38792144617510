import React from 'react';
import AppBar from 'components/dashboard/AppBar';
import Drawer from 'components/dashboard/Drawer';
import { isBrowser  } from "react-device-detect";

export default function () {

    const [open, setOpen] = React.useState<boolean>(isBrowser);

    return (
        <>
            <AppBar open={open} setOpen={setOpen} />
            <Drawer open={open} setOpen={setOpen} />
        </>
    );
}