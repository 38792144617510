import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'relay-hooks';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { TagList_allTags$key } from 'components/views/tag/__generated__/TagList_allTags.graphql';
import TagDeleteMutation from 'mutations/tag/TagDeleteMutation';
import { URL_NAMES } from 'projectConstants';
import MaterialTable from 'components/table/MaterialTable';

const fragmentSpec = graphql`
    fragment TagList_allTags on TagNodeConnection {
        totalCount,
        edges {
            node {
                id,
                name,
                category,
            }
        }
    }
`;

const header = [
    { id: 'node.name', label: 'Name' },
    { id: 'node.category', label: 'Category', lookup: { 
        'workout': 'Workout', 
        'nutrition': 'Meal', 
        'challenge': 'Challenge' 
    } },
];

interface TagListProps {
    allTags: TagList_allTags$key;
    relay: { retry: () => void | undefined };
}

export default function (props: TagListProps) {
    const { relay: { retry }, allTags } = props;
    const { edges, totalCount } = useFragment(fragmentSpec, allTags);
    const { enqueueSnackbar } = useSnackbar();

    const onDelete = async (ids: string[]) => {
        const response = await TagDeleteMutation.commit(ids);
        if (response?.deleteTags?.errors) {
            enqueueSnackbar('Could not successfully delete all selected elements', {variant: 'error'});
        } else {
            enqueueSnackbar('Successfully deleted', {variant: 'success'});
        }
        retry();
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <MaterialTable
                    header={header}
                    data={edges}
                    idField='node.id'
                    totalCount={totalCount}
                    title='Tags'
                    deleteClickHandler={(ids, confirmed) => confirmed ? onDelete(ids) : null}
                    addUrlName={URL_NAMES.TAG.CREATE}
                    editUrlName={URL_NAMES.TAG.UPDATE}
                />
            </Grid>
        </Grid>
    );
}