import Button from '@material-ui/core/Button/Button';
import Alert from '@material-ui/lab/Alert/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle/AlertTitle';
import React from 'react';

interface Props {
    onConnect: () => void;
}

export default function({ onConnect }: Props) {
    return (
    <Alert variant="outlined" severity="error" action={
        <Button color="inherit" size="large" onClick={onConnect}>Connect</Button>
    }>
        <AlertTitle>Stripe not connected</AlertTitle>
        You need to connect a Stripe account, before you are able to sell and earn money. If you already connected a Stripe account, eventually some information is still missing.
    </Alert>
    )
}