/* tslint:disable */
/* eslint-disable */
/* @relayHash 97563fc1f94e64a3f7a63bfc881cfce7 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MeQueryVariables = {};
export type MeQueryResponse = {
    readonly me: {
        readonly " $fragmentRefs": FragmentRefs<"ProfileUpdate_me">;
    } | null;
};
export type MeQuery = {
    readonly response: MeQueryResponse;
    readonly variables: MeQueryVariables;
};



/*
query MeQuery {
  me {
    ...ProfileUpdate_me
    id
  }
}

fragment ProfileUpdate_me on UserNode {
  id
  firstName
  lastName
  email
  username
  status {
    verified
    id
  }
  stripeEnabled
  currency
  amount
  creatorProfile {
    id
    bio
    profileImage {
      id
      image
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MeQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "UserNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ProfileUpdate_me",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MeQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "UserNode",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "status",
            "storageKey": null,
            "args": null,
            "concreteType": "UserStatusNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "verified",
                "args": null,
                "storageKey": null
              },
              (v0/*: any*/)
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "stripeEnabled",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "currency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "amount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "creatorProfile",
            "storageKey": null,
            "args": null,
            "concreteType": "CreatorProfileNode",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "bio",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "profileImage",
                "storageKey": null,
                "args": null,
                "concreteType": "ImageNode",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "image",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "MeQuery",
    "id": null,
    "text": "query MeQuery {\n  me {\n    ...ProfileUpdate_me\n    id\n  }\n}\n\nfragment ProfileUpdate_me on UserNode {\n  id\n  firstName\n  lastName\n  email\n  username\n  status {\n    verified\n    id\n  }\n  stripeEnabled\n  currency\n  amount\n  creatorProfile {\n    id\n    bio\n    profileImage {\n      id\n      image\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '8de57885583d579ae1264eaf93a969bd';
export default node;
