import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import { TagDeleteMutation } from 'mutations/tag/__generated__/TagDeleteMutation.graphql';

const mutation = graphql`
    mutation TagDeleteMutation($ids: [ID]!) {
        deleteTags(ids: $ids) {
            errors {
                field,
                messages
            }
        }
    }
`;

function commit(
    ids: string[]
) {
    return commitMutation<TagDeleteMutation>(
        environment,
        {
            mutation,
            variables: {
                ids
            },
        }
    );
}

export default { commit };