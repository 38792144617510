import React from 'react';
import _ from 'lodash';

export interface FormikInstance {
    touched: { [key: string]: boolean | undefined | any[] }
    errors: { [key: string]: string | undefined | any[] }
    status?: { [key: string]: string }
    handleChange: any,
    handleBlur: any,
    values: {[key: string]: any},
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const useFormikErrors = (formik: FormikInstance): [(fieldName: string) => boolean, (fieldName: string) => React.ReactNode] => {
    const showErrors = (fieldName: string) => {
        const status = formik.status ? _.get(formik.status, fieldName) : undefined;
        return !!_.get(formik.touched, fieldName) && (!!_.get(formik.errors, fieldName) || !!status);
    }
    const getErrors = (fieldName: string) => {
        const errors = _.get(formik.errors, fieldName);
        const status = formik.status ? _.get(formik.status, fieldName) : undefined;
        const shouldShowErrors = showErrors(fieldName);
        if (!shouldShowErrors) return null;
        if (errors && !status) return errors;
        else if (!errors && status) return status;
        else if (errors && status) return <>{errors}<br />{status}</>;
        else return null;
    }
    return [showErrors, getErrors]
};

interface GraphQLErrors {
    readonly errors: ReadonlyArray<{
        readonly field: string;
        readonly messages: ReadonlyArray<string>;
    } | null> | null;
}

export const transformToFormikErrors = (data: GraphQLErrors | null) => {
    if (data === null) return;
    const errors = data.errors
    if (errors === null) return;
    return errors.reduce((obj, item) => {
        if (item !== null) obj[item.field] = item.messages.join('\n');
        return obj;
    }, {} as { [key: string]: string });
}
