import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid, { GridSize } from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from 'notistack';
import { transformToFormikErrors } from 'utils/formikUtils';
import GlobalErrorField from 'components/fields/GlobalErrorField';
import TagCreateUpdateMutation from 'mutations/tag/TagCreateUpdateMutation';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import SelectField from 'components/fields/SelectField';
import { useRouter } from 'found';
import FormikInputField from 'components/fields/FormikInputField';
import SubmitButton from 'components/fields/SubmitButton';
import { URL_NAMES, YUP_MESSAGES } from 'projectConstants';

const fieldBreakPoints: { [key: string]: GridSize } = { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 };

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    }
}));

export default function () {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { router } = useRouter();
    const formik = useFormik({
        initialValues: {
            name: '',
            category: '',
        },
        onSubmit: async (values, { setStatus }) => {
            const response = await TagCreateUpdateMutation.commit(
                {
                    name: values.name,
                    category: values.category,
                }
            );
            if (response?.createUpdateTag?.errors) {
                setStatus(transformToFormikErrors(response.createUpdateTag));
            } else {
                setStatus({});
                enqueueSnackbar('Successfully created', { variant: 'success' });
                router.push(URL_NAMES.TAG.LIST);
            }
        },
        validationSchema:
            Yup.object().shape({
                name: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
                category: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
            })
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                        <Grid container item spacing={2} {...fieldBreakPoints} direction="column">
                            <Grid item xs={12}>
                                <Typography variant="h5">Create Tag</Typography>
                            </Grid>
                            <GlobalErrorField formik={formik} Parent={Grid} item xs={12} />
                            <Grid item xs={12}>
                                <FormikInputField
                                    Component={TextField}
                                    formik={formik}
                                    fieldName='name'
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikInputField
                                    Component={SelectField}
                                    formik={formik}
                                    fieldName='category'
                                    choices={{ 'workout': 'Workout', 'nutrition': 'Meal', 'challenge': 'Challenge' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SubmitButton label='Create' isSubmitting={formik.isSubmitting} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}
