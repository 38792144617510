import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import { ProfileCreateUpdateMutation, CreatorProfileCreateUpdateInput } from 'mutations/profile/__generated__/ProfileCreateUpdateMutation.graphql';

const mutation = graphql`
    mutation ProfileCreateUpdateMutation($input: CreatorProfileCreateUpdateInput!) {
        createUpdateCreatorProfile(input: $input) {
            id,
            errors {
                field,
                messages
            }
        }
    }
`;

function commit(input: CreatorProfileCreateUpdateInput) {
    return commitMutation<ProfileCreateUpdateMutation>(
        environment,
        {
            mutation,
            variables: {
                input
            },
        }
    );
}

export default {commit};