import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import { GenerateUploadURLInput, UploadURLCreateMutation } from 'mutations/upload/__generated__/UploadURLCreateMutation.graphql';

const mutation = graphql`
    mutation UploadURLCreateMutation($input: GenerateUploadURLInput!) {
        createUploadUrl(input: $input) {
            url
            fields
            objectKey
        }
    }
`;

function commit(
    input: GenerateUploadURLInput
) {
    return commitMutation<UploadURLCreateMutation>(
        environment,
        {
            mutation,
            variables: {
                input
            },
        }
    );
}

export default {commit};