import React from 'react';
import { Link as FoundLink } from 'found';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useRouter } from 'found';
import FormikInputField from 'components/fields/FormikInputField';
import SubmitButton from 'components/fields/SubmitButton';
import LoginUserMutation from 'mutations/auth/LoginUserMutation';
import setTokenExp from 'utils/setTokenExp';
import GlobalErrorField from 'components/fields/GlobalErrorField';
import Copyright from 'components/common/Copyright';
import { transformToFormikErrors } from 'utils/formikUtils';
import { URL_NAMES, YUP_MESSAGES } from 'projectConstants';
import FormikPasswordField from 'components/fields/FormikPasswordField';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function () {
    const classes = useStyles();
    const { router } = useRouter();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: async (values, { setStatus }) => {
            const response = await LoginUserMutation.commit(
                values.email, values.password
            );
            if (response?.tokenAuth?.errors) {
                setStatus(transformToFormikErrors(response.tokenAuth));
            } else {
                setStatus({});
                setTokenExp(response);
                router.push(URL_NAMES.HOME);
            }
        },
        validationSchema:
            Yup.object().shape({
                email: Yup.string()
                    .email(YUP_MESSAGES.EMAIL)
                    .required(YUP_MESSAGES.REQUIRED),
                password: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED)
            })
    });

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} onSubmit={formik.handleSubmit}
                    noValidate>
                    <GlobalErrorField formik={formik} />
                    <FormikInputField
                        Component={TextField}
                        formik={formik}
                        fieldName='email'
                        label="Email Address"
                        margin='normal'
                        autoFocus
                    />
                    <FormikPasswordField
                        formik={formik}
                        fieldName='password'
                        margin='normal'
                    />
                    <SubmitButton label='Sign In' className={classes.submit} fullWidth />
                    <Grid container>
                        <Grid item xs>
                            <FoundLink as={Link} to={URL_NAMES.AUTH.FORGOT} variant='body2'>
                                Forgot password?
                            </FoundLink>
                        </Grid>
                        <Grid item>
                            <FoundLink as={Link} to={URL_NAMES.AUTH.REGISTER} variant='body2'>
                                Don't have an account? Sign Up
                            </FoundLink>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}
