export const TOKEN_EXP_KEY = 'jwtTokenExpiresIn';
export const REFRESH_EXP_KEY = 'jwtRefreshTokenExpiresIn';
export const GLOBAL_ERROR_KEY = 'nonFieldErrors';

// list options
export const INITIAL_PAGE = 0;
export const INITIAL_PAGE_SIZE = 5;
export const INITIAL_ORDER = 'asc';
export const INITIAL_ORDER_BY = 'id';
export const PAGE_SIZE_OPTIONS = [5, 10, 15];
export const LIST_FETCH_POLICY = 'store-and-network';

// url constants
export const URL_NAMES = {
    HOME: 'home',
    AUTH: {
        LOGIN: 'login',
        REGISTER: 'register',
        FORGOT: 'forgot',
        CONFIRM: 'confirm',
        RESET: 'reset'
    },
    PROFILE: 'profile',
    ARTICLE: {
        LIST: 'article-list',
        CREATE: 'article-create',
        UPDATE: 'article-update'
    },
    VIDEO: {
        LIST: 'video-list',
        CREATE: 'video-create',
        UPDATE: 'video-update'
    },
    TAG: {
        LIST: 'tag-list',
        CREATE: 'tag-create',
        UPDATE: 'tag-update'
    },
    STRIPE: {
        REFRESH: 'refresh',
        RETURN: 'return'
    }
};

export const YUP_MESSAGES = {
    REQUIRED: 'Required',
    EMAIL: 'Please enter a valid email',
    INTEGER: 'Please enter a valid integer',
    NO_NEGATIVE: 'Negative numbers are not allowed',
    PRICE_LOWER_THAN_100: 'Price should be lower than 100',
    PRICE_HIGHER_THAN_5: 'Price should be higher than 5'
}

export const NO_AUTH_RELAY_ACTIONS = [
    'LoginUserMutation',
    'RefreshTokenMutation',
    'RegisterUserMutation',
    'SendPasswordResetMutation',
    'ResetPasswordMutation',
    'ConfirmAccountMutation'
];

export const UPLOAD_TITLES = {
    ARTICLE: {
        THUMBNAIL: 'Drag \'n\' drop a thumbnail image for the article here, or click to select the thumbnail.'
    },
    VIDEO: {
        THUMBNAIL: 'Drag \'n\' drop a thumbnail image for the video here, or click to select the thumbnail.',
        UPLOAD: 'Drag \'n\' drop a video here, or click to select the video.',
    },
    PROFILE: {
        IMAGE: 'Drag \'n\' drop a profile image, or click to select the image.'
    }
}

export const UPLOAD_MIME_TYPES = {
    IMAGE: 'image/jpeg, image/png, image/gif, image/webp',
    VIDEO: 'video/mp4, video/mpeg, video/x-msvideo, video/ogg, video/webm, video/quicktime'
}

export const UPLOAD_MAX_FILE_SIZE = {
    IMAGE: 20 * Math.pow(10, 6), // 20MB
    VIDEO: 200 * Math.pow(10, 6) // 200MB
}