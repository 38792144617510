import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid, { GridSize } from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { transformToFormikErrors, useFormikErrors } from 'utils/formikUtils';
import GlobalErrorField from 'components/fields/GlobalErrorField';
import UpdateUserMutation from 'mutations/user/UpdateUserMutation';
import ChangePasswordMutation from 'mutations/user/ChangePasswordMutation';
import Paper from '@material-ui/core/Paper';
import FormikInputField from 'components/fields/FormikInputField';
import SubmitButton from 'components/fields/SubmitButton';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'relay-hooks';
import { ProfileUpdate_me$key } from 'components/views/profile/__generated__/ProfileUpdate_me.graphql';
import { UPLOAD_MAX_FILE_SIZE, UPLOAD_MIME_TYPES, UPLOAD_TITLES, YUP_MESSAGES } from 'projectConstants';
import FormikPasswordField from 'components/fields/FormikPasswordField';
import Typography from '@material-ui/core/Typography/Typography';
import SendActivationMailMutation from 'mutations/auth/SendActivationMailMutation';
import StripeNotConnectedError from 'components/views/profile/StripeNotConnectedError';
import ConnectMutation from 'mutations/stripe/ConnectMutation';
import ProfileCreateUpdateMutation from 'mutations/profile/ProfileCreateUpdateMutation';
import { CreatorProfileCreateUpdateInput } from 'mutations/profile/__generated__/ProfileCreateUpdateMutation.graphql';
import { FileUpload, imageUpdate } from 'utils/upload';
import ImageDeleteMutation from 'mutations/image/ImageDeleteMutation';
import DraggableHorizontalList from 'components/common/DraggableHorizontalList';
import ImagePreview from 'components/common/ImagePreview';
import StyledDropZone from 'components/common/StyledDropZone';
import ConfirmEmailWarning from './ConfirmEmailWarning';
import SelectField from 'components/fields/SelectField';
import CurrencyField from 'components/fields/CurrencyField';

const fragmentSpec = graphql`
    fragment ProfileUpdate_me on UserNode {
        id,
        firstName,
        lastName,
        email,
        username,
        status {
            verified
        }
        stripeEnabled
        currency
        amount
        creatorProfile {
            id
            bio
            profileImage {
                id
                image
            }
        }
    }
`;

const CURRENCY_CHOICES = {
    usd: 'USD',
    eur: 'EUR',
}

const fieldBreakPoints: { [key: string]: GridSize } = { xs: 12, sm: 8, md: 10, lg: 8, xl: 8 };

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
}));

interface ProfileUpdateProps {
    me: ProfileUpdate_me$key;
}

export default function (props: ProfileUpdateProps) {
    const classes = useStyles();
    const [deletedImages, setDeletedImages] = useState<any[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const me = useFragment(fragmentSpec, props.me);
    let profileImages: FileUpload[] = [];
    if (me.creatorProfile?.profileImage) {
        profileImages = [{ id: me.creatorProfile.profileImage.id, order: 0, preview: me.creatorProfile.profileImage.image }]
    }
    const formik = useFormik({
        initialValues: {
            email: me.email,
            firstName: me.firstName,
            lastName: me.lastName,
            username: me.username ? me.username : '',
            currency: me.currency ? me.currency : '',
            price: me.amount ? me.amount / 100 : '',
            bio: me.creatorProfile?.bio ? me.creatorProfile?.bio : '',
            profileImages: profileImages,
        },
        onSubmit: async (values, { setStatus }) => {
            let imageId = undefined;
            if (deletedImages.length > 0) {
                await ImageDeleteMutation.commit([deletedImages[0].id])
            }
            if (values.profileImages.length > 0) {
                imageId = await imageUpdate(values.profileImages[0]);
            }
            const response = await UpdateUserMutation.commit({
                id: me.id, 
                email: values.email, 
                firstName: values.firstName, 
                lastName: values.lastName, 
                username: values.username,  
                currency: values.currency,
                amount: Number(values.price) * 100,
                password: 'doesntMatterCannotBeUpdated'
            });
            if (response?.createUpdateUser?.errors) {
                setStatus(transformToFormikErrors(response.createUpdateUser));
            } else {
                setStatus({});
            }
            let variables: CreatorProfileCreateUpdateInput = {
                bio: values.bio, user: me.id, profileImage: imageId
            }
            if (me.creatorProfile) {
                variables = {...variables, id: me.creatorProfile.id}
            }
            const profileUpdateResponse = await ProfileCreateUpdateMutation.commit(variables);
            if (profileUpdateResponse.createUpdateCreatorProfile?.errors) {
                setStatus(transformToFormikErrors({...response.createUpdateUser, ...profileUpdateResponse.createUpdateCreatorProfile}));
            } else {
                setStatus({});
                enqueueSnackbar('Successfully updated', { variant: 'success' });
            }
        },
        validationSchema:
            Yup.object().shape({
                firstName: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
                lastName: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
                username: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
                email: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED)
                    .email(YUP_MESSAGES.EMAIL),
                bio: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
                currency: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
                price: Yup.number()
                    .required(YUP_MESSAGES.REQUIRED)
                    .min(5, YUP_MESSAGES.PRICE_HIGHER_THAN_5)
                    .max(100, YUP_MESSAGES.PRICE_LOWER_THAN_100),
                profileImages: Yup.array()
                    .required(YUP_MESSAGES.REQUIRED),
            })
    });

    const formikChangePassword = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: ''
        },
        onSubmit: async (values, { setStatus }) => {
            const response = await ChangePasswordMutation.commit(
                values.currentPassword, values.newPassword
            );
            if (response?.changePassword?.errors) {
                setStatus(transformToFormikErrors(response.changePassword));
            } else {
                setStatus({});
                enqueueSnackbar('Successfully changed password', { variant: 'success' });
            }
        },
        validationSchema:
            Yup.object().shape({
                currentPassword: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
                newPassword: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED)
            })
    });

    const resendConfirmation = async () => {
        SendActivationMailMutation.commit(me.email)
            .then(response => {
                if (response?.sendActivationMail?.errors) {
                    const errorMessages = response.sendActivationMail.errors;
                    errorMessages.forEach(errorObject => {
                        if (errorObject) enqueueSnackbar(errorObject.messages[0], { variant: 'error' });
                    });

                } else {
                    enqueueSnackbar('Activation mail sent successfully', { variant: 'success' });
                }
            })
    }

    const handleStripeConnect = async () => {
        const response = await ConnectMutation.commit({});
        if (response?.stripeConnect?.errors) {
            enqueueSnackbar(response.stripeConnect.errors.toString(), { variant: 'error' });
        } else if (!response.stripeConnect || !response.stripeConnect.redirectUrl) {
            enqueueSnackbar('Unhandled error', { variant: 'error' });
        } else {
            window.location.replace(response.stripeConnect.redirectUrl);
        }
    }

    const setValue = (value: {[key: string]: any}) => {
        if (formik.values.profileImages.length > 0 && formik.values.profileImages[0].id !== undefined) {
            setDeletedImages([formik.values.profileImages[0]]);
        }
        formik.setFieldValue('profileImages', value)
    }

    const [showErrors, getErrors] = useFormikErrors(formik);

    return (
        <Grid container spacing={3}>
            {
                me.stripeEnabled
                ? null
                : <Grid item xl={12}>
                    <StripeNotConnectedError onConnect={handleStripeConnect} />
                </Grid>
            }
            {
                me.status?.verified
                ? null
                : <Grid item xl={12}>
                    <ConfirmEmailWarning onResend={resendConfirmation} />
                </Grid>
            }
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Paper className={classes.paper}>
                    <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                        <Grid container item spacing={2} {...fieldBreakPoints} direction='column'>
                            <Grid item>
                                <Typography variant='h5'>Update Profile</Typography>
                            </Grid>
                            <GlobalErrorField formik={formik} Parent={Grid} item />
                            <Grid item>
                                <FormikInputField
                                    Component={TextField}
                                    formik={formik}
                                    fieldName='firstName'
                                    label='First Name'
                                    autoFocus
                                />
                            </Grid>
                            <Grid item>
                                <FormikInputField
                                    Component={TextField}
                                    formik={formik}
                                    fieldName='lastName'
                                    label='Last Name'
                                />
                            </Grid>
                            <Grid item>
                                <FormikInputField
                                    Component={TextField}
                                    formik={formik}
                                    fieldName='username'
                                />
                            </Grid>
                            <Grid item>
                                <FormikInputField
                                    Component={TextField}
                                    formik={formik}
                                    fieldName='email'
                                />
                            </Grid>
                            <Grid item>
                                <FormikInputField
                                    Component={TextField}
                                    formik={formik}
                                    fieldName='bio'
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <Grid item>
                                <FormikInputField
                                    Component={SelectField}
                                    formik={formik}
                                    fieldName='currency'
                                    choices={CURRENCY_CHOICES}
                                />
                            </Grid>
                            {
                                formik.values.currency
                                    ? (
                                        <Grid item>
                                            <FormikInputField
                                                Component={CurrencyField}
                                                formik={formik}
                                                fieldName='price'
                                                currency={formik.values.currency}
                                            />
                                        </Grid>
                                    )
                                    : null
                            }
                            <Grid item xs={12}>
                                <StyledDropZone
                                    error={showErrors('profileImages')}
                                    errorText={getErrors('profileImages')}
                                    value={formik.values.profileImages}
                                    setValue={setValue}
                                    accept={UPLOAD_MIME_TYPES.IMAGE}
                                    maxSize={UPLOAD_MAX_FILE_SIZE.IMAGE}
                                    title={UPLOAD_TITLES.PROFILE.IMAGE}
                                />
                            </Grid>
                            {
                                formik.values.profileImages.length > 0
                                    ? (
                                        <Grid item container wrap='wrap' xs={12}>
                                            <DraggableHorizontalList
                                                droppableId='image-list'
                                                list={formik.values.profileImages}
                                                setList={setValue}
                                                deletedElements={deletedImages}
                                                setDeletedElements={setDeletedImages}
                                                ElementComponent={ImagePreview}
                                            />
                                        </Grid>
                                    )
                                    : null
                            }
                            <Grid item>
                                <SubmitButton label='Save' />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Paper className={classes.paper}>
                    <form className={classes.form} onSubmit={formikChangePassword.handleSubmit} noValidate>
                        <Grid container item spacing={2} {...fieldBreakPoints} direction='column'>
                            <Grid item>
                                <Typography variant='h5'>Change Password</Typography>
                            </Grid>
                            <GlobalErrorField formik={formikChangePassword} Parent={Grid} item />
                            <Grid item>
                                <FormikPasswordField
                                    formik={formikChangePassword}
                                    fieldName='currentPassword'
                                    label='Current Password'
                                />
                            </Grid>
                            <Grid item>
                                <FormikPasswordField
                                    formik={formikChangePassword}
                                    fieldName='newPassword'
                                    label='New Password'
                                />
                            </Grid>
                            <Grid item>
                                <SubmitButton label='Change' />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}
