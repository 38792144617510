import ImageCreateUpdateMutation from 'mutations/image/ImageCreateUpdateMutation';
import UploadURLCreateMutation from 'mutations/upload/UploadURLCreateMutation';
import { UploadURLCreateMutationResponse } from 'mutations/upload/__generated__/UploadURLCreateMutation.graphql';
import VideoCreateUpdateMutation from 'mutations/video/VideoCreateUpdateMutation';


export interface FileUpload {
    [key: string]: any
}

async function uploadFileToAWS(uploadURLResponse: UploadURLCreateMutationResponse, file: FileUpload) {
    const formData = new FormData();
    Object.entries(uploadURLResponse.createUploadUrl?.fields as {[key: string]: any}).forEach(([key, value]) => {
        formData.append(key, value);
      });
    formData.append('file', (file as unknown) as Blob);
    const uploadResponse = await fetch((uploadURLResponse.createUploadUrl?.url as string), {
        method: 'POST',
        body: formData,
        
    })
    return uploadResponse;
}

export async function imageCreate(image: FileUpload) {
    let imageId: string | null = null;
    const uploadURLResponse = await UploadURLCreateMutation.commit({
        uploadType: 'IMAGE_UPLOAD', fileName: image.name
    })
    if (uploadURLResponse.createUploadUrl?.url) {
        const uploadResponse = await uploadFileToAWS(uploadURLResponse, image);
        if (uploadResponse.status === 204 && uploadURLResponse.createUploadUrl.objectKey) {
            const imageCreateResponse = await ImageCreateUpdateMutation.commit({
                image: uploadURLResponse.createUploadUrl.objectKey
            })
            if (imageCreateResponse.createUpdateImage?.id !== undefined) {
                imageId = imageCreateResponse.createUpdateImage?.id;
            }
        }
    }
    return imageId;
}

export async function imageUpdate(image: FileUpload) {
    if (image.id !== undefined) {
        return image.id
    }
    return imageCreate(image)
}

export async function videoCreate(video: FileUpload) {
    let videoId: string | null = null;
    const uploadURLResponse = await UploadURLCreateMutation.commit({
        uploadType: 'VIDEO_UPLOAD', fileName: video.name
    })
    if (uploadURLResponse.createUploadUrl?.url) {
        const uploadResponse = await uploadFileToAWS(uploadURLResponse, video);
        if (uploadResponse.status === 204 && uploadURLResponse.createUploadUrl.objectKey) {
            const videoCreateResponse = await VideoCreateUpdateMutation.commit({
                uploadedVideo: uploadURLResponse.createUploadUrl.objectKey
            })
            if (videoCreateResponse.createUpdateVideo?.id !== undefined) {
                videoId = videoCreateResponse.createUpdateVideo?.id;
            }
        }
    }
    return videoId;
}

export async function videoUpdate(video: FileUpload) {
    if (video.id !== undefined) {
        return video.id
    }
    return videoCreate(video)
}