import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import { ImageDeleteMutation } from 'mutations/image/__generated__/ImageDeleteMutation.graphql';

const mutation = graphql`
    mutation ImageDeleteMutation($ids: [ID]!) {
        deleteImages(ids: $ids) {
            errors {
                field,
                messages
            }
        }
    }
`;

function commit(
    ids: string[]
) {
    return commitMutation<ImageDeleteMutation>(
        environment,
        {
            mutation,
            variables: {
                ids
            },
        }
    );
}

export default { commit };