import React from 'react';
import _ from 'lodash';
import { FormikInstance, useFormikErrors } from 'utils/formikUtils';

interface FormikInputFieldProps {
    Component: React.ComponentType<any>,
    formik: FormikInstance,
    fieldName: string,
    [key: string]: any
}

export default function ({ Component, formik, fieldName, ...props }: FormikInputFieldProps) {
    const [showErrors, getErrors] = useFormikErrors(formik);
    const value = _.get(formik.values, fieldName);
    
    return (
        <Component
            autoComplete={fieldName}
            name={fieldName}
            variant="outlined"
            required={true}
            id={fieldName}
            // capitalize
            label={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
            onChange={(e: React.ChangeEvent<any>) => formik.setFieldValue(fieldName, e.target.value)}
            onBlur={formik.handleBlur(fieldName)}
            value={value === undefined ? '' : value}
            error={showErrors(fieldName)}
            helperText={getErrors(fieldName)}
            fullWidth
            {...props}
        />
    );
}