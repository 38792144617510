import graphql from "babel-plugin-relay/macro";

export interface TagNodeResponse {
    id: string;
    name: string;
}

export default graphql`
    query TagAutoCompleteQuery($name_Contains: String, $category: String) {
        tags(first: 100, orderBy: "name", name_Contains: $name_Contains, category: $category) {
            edges {
                node {
                    id,
                    name,
                }
            }
        }
    }
`