import React from 'react';
import PreviewElement from './PreviewElement';

interface Props {
    element: { [key: string]: any };
    onDelete?: (element: { [key: string]: any }) => void;
}

export default function ({ element, onDelete }: Props) {
    return <PreviewElement
        element={element}
        onDelete={onDelete}
        previewElement={<img src={element.preview} alt={element.name} />}
    />
}