/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type VideoList_allPosts = {
    readonly totalCount: number | null;
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly id: string;
            readonly title: string;
            readonly category: string;
            readonly video: {
                readonly id: string;
                readonly conversionStatus: string;
            } | null;
        } | null;
    } | null>;
    readonly " $refType": "VideoList_allPosts";
};
export type VideoList_allPosts$data = VideoList_allPosts;
export type VideoList_allPosts$key = {
    readonly " $data"?: VideoList_allPosts$data;
    readonly " $fragmentRefs": FragmentRefs<"VideoList_allPosts">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "VideoList_allPosts",
  "type": "PostNodeConnection",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "PostNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "PostNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "category",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "video",
              "storageKey": null,
              "args": null,
              "concreteType": "VideoNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "conversionStatus",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = '4951e29ae0822a2c7a68a1f8950ff9a1';
export default node;
