import {
    Environment,
    RecordSource,
    Store,
} from 'relay-runtime';
import {
    RelayNetworkLayer,
    urlMiddleware,
    loggerMiddleware,
    errorMiddleware,
    perfMiddleware,
} from 'react-relay-network-modern/es';
import authMiddleware from 'middlewares/authMiddleware';

const network = new RelayNetworkLayer(
    [
        urlMiddleware({
            url: (req) => Promise.resolve('/graphql'),
            headers: req => {
                return {
                    'X-CSRFToken': (window as any).csrftoken
                };
            }
        }),
        authMiddleware(),
        process.env.NODE_ENV !== 'production' ? loggerMiddleware() : null,
        process.env.NODE_ENV !== 'production' ? errorMiddleware() : null,
        process.env.NODE_ENV !== 'production' ? perfMiddleware() : null,
    ],
    {}
);

const source = new RecordSource();
const store = new Store(source);

const environment = new Environment({
    network: network,
    store: store,
});

export default environment;
