import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, createStyles, Theme, IconButton } from '@material-ui/core';
import { useRouter, Link } from 'found';
import MaterialTableHead, { HeadCell, Order, Lookup } from 'components/table/MaterialTableHead';
import MaterialTableToolbar from 'components/table/MaterialTableToolbar';
import EditIcon from '@material-ui/icons/Edit';
import { PAGE_SIZE_OPTIONS, INITIAL_PAGE, INITIAL_ORDER_BY, INITIAL_PAGE_SIZE, INITIAL_ORDER } from 'projectConstants';
import DeleteDialog from 'components/common/DeleteDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {},
  }),
);

interface MaterialTableProps {
  readonly data: ReadonlyArray<{ readonly [key: string]: any } | null> | null
  header: { id: string, label: string, lookup?: Lookup }[]
  idField: string
  rowsPerPageOptions: number[]
  initialRowsPerPage: number
  totalCount: number | null
  useEmptyRows: boolean
  initialPage: number
  title: string
  initialOrder: Order
  initialOrderBy: string
  deleteClickHandler: (ids: string[], confirmed: boolean) => void;
  editUrlName?: string,
  addUrlName?: string,
}

function queryToPageAndPageSize(query: { first?: string, after?: string }, initialPage: number, initialPageSize: number) {
  let pageSize = initialPageSize;
  if (query.first !== undefined) pageSize = parseInt(query.first);

  let page = initialPage;
  if (query.after !== undefined) {
    const afterId = parseInt(atob(query.after).split(':')[1]);
    page = (afterId + 1) / pageSize
  }
  return { page, pageSize };
}

export function pageAndPageSizeToQuery(page: number, pageSize: number) {
  const first = pageSize.toString();
  const after = btoa(`arrayconnection:${(page * pageSize) - 1}`);
  return { first, after };
}

function queryToOrderAndOrderBy(query: { orderBy?: string }, initialOrder: Order, initialOrderBy: string) {
  if (query.orderBy === undefined) {
    return { order: initialOrder, orderBy: initialOrderBy };
  }
  let order: Order = 'asc';
  let orderBy = query.orderBy;
  if (query.orderBy.startsWith('-')) {
    order = 'desc';
    orderBy = orderBy.substring(1);
  }
  return { order, orderBy }
}

export function orderAndOrderByToQuery(order: Order, orderBy: string) {
  let newOrderBy = orderBy;
  if (order === 'desc') newOrderBy = `-${newOrderBy}`
  return { orderBy: newOrderBy }
}

function MaterialTable(props: MaterialTableProps) {
  const classes = useStyles();
  const [confirmationOpen, setCofirmationOpen] = useState(false);
  const { data, idField, header, rowsPerPageOptions, initialRowsPerPage, totalCount, useEmptyRows, initialPage, title, initialOrder, initialOrderBy, deleteClickHandler, editUrlName, addUrlName } = props;
  const { match, router } = useRouter();
  const [selected, setSelected] = React.useState<string[]>([]);
  const { order, orderBy } = queryToOrderAndOrderBy(match.location.query, initialOrder, initialOrderBy);
  const { page, pageSize } = queryToPageAndPageSize(match.location.query, initialPage, initialRowsPerPage);
  const currentQuery = {
    ...pageAndPageSizeToQuery(page, pageSize),
    ...orderAndOrderByToQuery(order, orderBy)
  };
  const pathName = match.location.pathname;

  // sets default for data
  const getData = () => data ? data : [];

  // sets defaults for header array
  const getHeader = (): HeadCell[] => {
    let headers: HeadCell[] = header.map(h => ({ disablePadding: false, align: 'left', ...h }));
    if (editUrlName) headers = [...headers, { id: 'actions', label: 'Actions', disablePadding: false, align: 'left', sortable: false }];
    return headers;
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    const newQuery = { ...currentQuery, ...orderAndOrderByToQuery(isAsc ? 'desc' : 'asc', property) };
    router.push({ pathname: pathName, query: newQuery });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = getData().map((n) => _.get(n, idField));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string | number) => {
    if (typeof id === 'number') id = id.toString();
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    const newQuery = { ...currentQuery, ...pageAndPageSizeToQuery(newPage, pageSize) }
    router.push({ pathname: pathName, query: newQuery })
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = { ...currentQuery, ...pageAndPageSizeToQuery(initialPage, parseInt(event.target.value, 10)) };
    router.push({ pathname: pathName, query: newQuery })
  };

  const isSelected = (id: string | number) => {
    if (typeof id === 'number') id = id.toString();
    return selected.indexOf(id) !== -1;
  };

  let emptyRows: number;
  if (useEmptyRows) emptyRows = pageSize - getData().length;
  else emptyRows = 0;

  // in case we deleted elements, and no elements remain
  const shouldGoBack = getData().length === 0 && page !== 0;

  useEffect(() => {
    // go one page back because no elements remain
    if (shouldGoBack) {
      handleChangePage(undefined, page - 1);
    }
  })

  return (
    <Paper className={classes.paper}>
      <MaterialTableToolbar selected={selected} title={title} deleteClickHandler={() => setCofirmationOpen(true)} addUrlName={addUrlName} editUrlName={editUrlName} />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size='medium'
          aria-label="enhanced table"
        >
          <MaterialTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={getData().length}
            header={getHeader()}
          />
          <TableBody>
            {getData().map((row, index) => {
              const idFieldValue = _.get(row, idField);
              const isItemSelected = isSelected(idFieldValue);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, idFieldValue)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={idFieldValue}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>
                  {getHeader().map((headerRow, headerIndex) => {
                    if (headerRow.id === 'actions') {
                      return <TableCell
                        key={`${idFieldValue}-${headerIndex}`}
                        align={headerRow.align}
                        padding={headerRow.disablePadding ? 'none' : 'default'}
                      >
                        {
                          editUrlName && row
                            ? <Link
                              // @ts-ignore found-named-routes overrides this
                              to={{ name: editUrlName, params: { id: _.get(row, idField) } }}
                              onClick={event => event.stopPropagation()}>
                              <IconButton aria-label="create">
                                <EditIcon />
                              </IconButton>
                            </Link> : null
                        }
                      </TableCell>;
                    } else {
                      const cellValue = _.get(row, headerRow.id);
                      return <TableCell
                        key={`${idFieldValue}-${headerIndex}`}
                        align={headerRow.align}
                        padding={headerRow.disablePadding ? 'none' : 'default'}
                      >
                        {headerRow.lookup
                          ? (typeof headerRow.lookup === 'function' ? headerRow.lookup(cellValue) : headerRow.lookup[cellValue])
                          : cellValue}
                      </TableCell>;
                    }
                  })}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        labelRowsPerPage='Rows'
        component="div"
        count={totalCount || 0}
        rowsPerPage={pageSize}
        // skip error and use previous page if no elements remain
        page={shouldGoBack ? page - 1 : page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <DeleteDialog open={confirmationOpen} handleClose={(confirmed) => {
        deleteClickHandler(selected, confirmed);
        setSelected([]);
        setCofirmationOpen(false);
      }} />
    </Paper>
  );
}

MaterialTable.defaultProps = {
  rowsPerPageOptions: PAGE_SIZE_OPTIONS,
  initialRowsPerPage: INITIAL_PAGE_SIZE,
  useEmptyRows: false,
  initialPage: INITIAL_PAGE,
  initialOrder: INITIAL_ORDER,
  initialOrderBy: INITIAL_ORDER_BY
}

MaterialTable.propTypes = {
  totalCount: PropTypes.number.isRequired
}

export default MaterialTable;