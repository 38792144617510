import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import { RefreshTokenMutation } from 'mutations/auth/__generated__/RefreshTokenMutation.graphql';

const mutation = graphql`
    mutation RefreshTokenMutation($input: RefreshInput!) {
        refreshToken(input: $input) {
            token,
            refreshExpiresIn
        }
    }
`;

function commit() {
    return commitMutation<RefreshTokenMutation>(
        environment,
        {
            mutation,
            variables: {input: {}},
        }
    );
}

export default {commit};