import { PropTypes } from "@material-ui/core";

const colors: {[key:string]:string} = {
    not_converted: '#9e9e9e',
    in_progress: '#ffeb3b',
    converted: '#4caf50',
    video_conversion_error: '#f44336',
    thumbnail_generation_error: '#f44336',
    setting_metadata_error: '#f44336',
    unknown_error: '#f44336',
}

export const conversionStatusToDisplay = (conversionStatus: string) => {
    switch (conversionStatus) {
        case 'not_converted':
            return 'Not ready';
        case 'in_progress':
            return 'Preparing';
        case 'converted':
            return 'Ready';
        case 'video_conversion_error':
            return 'Error';
        case 'thumbnail_generation_error':
            return 'Error';
        case 'setting_metadata_error':
            return 'Error';
        case 'unknown_error':
            return 'Error';
        default:
            break;
    }
}

export const generatePillColor = (conversionStatus: string) => {
    const color = getKeyValue(colors)(conversionStatus)
    return color as PropTypes.Color
}

const getKeyValue = <T extends object, U extends keyof T>(obj: T) => (key: U) => obj[key];