/* tslint:disable */
/* eslint-disable */
/* @relayHash e3387d92243139a9335d90d3e83d249c */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ArticleUpdateQueryVariables = {
    id: string;
};
export type ArticleUpdateQueryResponse = {
    readonly post: {
        readonly " $fragmentRefs": FragmentRefs<"ArticleUpdate_post">;
    } | null;
};
export type ArticleUpdateQuery = {
    readonly response: ArticleUpdateQueryResponse;
    readonly variables: ArticleUpdateQueryVariables;
};



/*
query ArticleUpdateQuery(
  $id: ID!
) {
  post(id: $id) {
    ...ArticleUpdate_post
    id
  }
}

fragment ArticleUpdate_post on PostNode {
  id
  title
  calories
  category
  content
  postType
  thumbnail {
    id
    image
  }
  tags {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ArticleUpdateQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "post",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PostNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ArticleUpdate_post",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ArticleUpdateQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "post",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PostNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "calories",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "category",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "content",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "postType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "thumbnail",
            "storageKey": null,
            "args": null,
            "concreteType": "ImageNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "image",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tags",
            "storageKey": null,
            "args": null,
            "concreteType": "TagNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TagNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ArticleUpdateQuery",
    "id": null,
    "text": "query ArticleUpdateQuery(\n  $id: ID!\n) {\n  post(id: $id) {\n    ...ArticleUpdate_post\n    id\n  }\n}\n\nfragment ArticleUpdate_post on PostNode {\n  id\n  title\n  calories\n  category\n  content\n  postType\n  thumbnail {\n    id\n    image\n  }\n  tags {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '28616ea7a53f6813378889f6172c7274';
export default node;
