import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import {ChangePasswordMutation} from 'mutations/user/__generated__/ChangePasswordMutation.graphql';

const mutation = graphql`
    mutation ChangePasswordMutation($input: PasswordChangeInput!) {
        changePassword(input: $input) {
            errors {
                field,
                messages
            }
        }
    }
`;

function commit(
    currentPassword: string,
    newPassword: string
) {
    return commitMutation<ChangePasswordMutation>(
        environment,
        {
            mutation,
            variables: {
                input: {
                    currentPassword,
                    newPassword
                },
            },
        }
    );
}

export default {commit};