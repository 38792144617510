/* tslint:disable */
/* eslint-disable */
/* @relayHash da274943ed3d3a626a08078be6343ef5 */

import { ConcreteRequest } from "relay-runtime";
export type RefreshInput = {
    refreshToken?: string | null;
    clientMutationId?: string | null;
};
export type RefreshTokenMutationVariables = {
    input: RefreshInput;
};
export type RefreshTokenMutationResponse = {
    readonly refreshToken: {
        readonly token: string;
        readonly refreshExpiresIn: number;
    } | null;
};
export type RefreshTokenMutation = {
    readonly response: RefreshTokenMutationResponse;
    readonly variables: RefreshTokenMutationVariables;
};



/*
mutation RefreshTokenMutation(
  $input: RefreshInput!
) {
  refreshToken(input: $input) {
    token
    refreshExpiresIn
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RefreshInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "refreshToken",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RefreshPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "token",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "refreshExpiresIn",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RefreshTokenMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "RefreshTokenMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "RefreshTokenMutation",
    "id": null,
    "text": "mutation RefreshTokenMutation(\n  $input: RefreshInput!\n) {\n  refreshToken(input: $input) {\n    token\n    refreshExpiresIn\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ea576912d9d84931b4521252e8b92bd4';
export default node;
