import React from 'react';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import Grid, { GridSize } from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from 'notistack';
import { transformToFormikErrors, useFormikErrors } from 'utils/formikUtils';
import GlobalErrorField from 'components/fields/GlobalErrorField';
import PostCreateUpdateMutation from 'mutations/post/PostCreateUpdateMutation';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import SelectField from 'components/fields/SelectField';
import { useRouter } from 'found';
import FormikInputField from 'components/fields/FormikInputField';
import SubmitButton from 'components/fields/SubmitButton';
import { UPLOAD_MAX_FILE_SIZE, UPLOAD_MIME_TYPES, UPLOAD_TITLES, URL_NAMES, YUP_MESSAGES } from 'projectConstants';
import IntegerFormikInputField from 'components/fields/IntegerFormikInputField';
import Editor from 'components/common/Editor';
import StyledDropZone from 'components/common/StyledDropZone';
import DraggableHorizontalList from 'components/common/DraggableHorizontalList';
import ImagePreview from 'components/common/ImagePreview';
import { imageCreate } from 'utils/upload';
import AutoComplete from 'components/fields/AutoComplete';
import { useLazyLoadQuery } from 'relay-hooks';
import TagAutoCompleteQuery from 'queries/tag/TagAutoCompleteQuery';
import { TagAutoCompleteQuery as TagAutoCompleteQueryType } from 'queries/tag/__generated__/TagAutoCompleteQuery.graphql';

const fieldBreakPoints: { [key: string]: GridSize } = { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 };

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
}));

export default function () {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { router } = useRouter();

    const formik = useFormik({
        initialValues: {
            title: '',
            calories: 0,
            category: '',
            tags: [],
            tagsTextField: '',
            thumbnails: [],
            content: '',
        },
        onSubmit: async (values, { setStatus }) => {
            let imageId = undefined;
            if (values.thumbnails.length > 0) {
                imageId = await imageCreate(values.thumbnails[0]);
            }
            const response = await PostCreateUpdateMutation.commit(
                {
                    title: values.title,
                    calories: values.calories,
                    category: values.category,
                    tags: values.tags.map((tag: {id: string}) => tag.id),
                    content: values.content,
                    postType: 'article',
                    thumbnail: imageId,
                }
            );
            if (response?.createUpdatePost?.errors) {
                setStatus(transformToFormikErrors(response.createUpdatePost));
            } else {
                setStatus({});
                enqueueSnackbar('Successfully created', { variant: 'success' });
                router.push(URL_NAMES.ARTICLE.LIST);
            }
        },
        validationSchema:
            Yup.object().shape({
                title: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
                calories: Yup.number()
                    .integer(YUP_MESSAGES.INTEGER)
                    .min(0, YUP_MESSAGES.NO_NEGATIVE)
                    .required(YUP_MESSAGES.REQUIRED),
                category: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
                tags: Yup.array(),
                thumbnails: Yup.array()
                    .required(YUP_MESSAGES.REQUIRED),
                content: Yup.string(),
            })
    });

    const data = useLazyLoadQuery<TagAutoCompleteQueryType>(
            TagAutoCompleteQuery,
            {name_Contains: formik.values.tagsTextField, category: formik.values.category},
        )

    const [showErrors, getErrors] = useFormikErrors(formik);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                        <Grid container item spacing={2} {...fieldBreakPoints} direction="column">
                            <Grid item xs={12}>
                                <Typography variant="h5">Create Article</Typography>
                            </Grid>
                            <GlobalErrorField formik={formik} Parent={Grid} item xs={12} />
                            <Grid item xs={12}>
                                <FormikInputField
                                    Component={TextField}
                                    formik={formik}
                                    fieldName='title'
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <IntegerFormikInputField
                                    formik={formik}
                                    fieldName='calories'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikInputField
                                    Component={SelectField}
                                    formik={formik}
                                    fieldName='category'
                                    choices={{ 'workout': 'Workout', 'nutrition': 'Meal', 'challenge': 'Challenge' }}
                                />
                            </Grid>
                            {
                                formik.values.category
                                ? (
                                    <Grid item xs={12}>
                                        <AutoComplete
                                            formik={formik}
                                            fieldName='tags'
                                            options={_.map(data.props?.tags?.edges, edge => edge?.node)}
                                            optionLabelFieldName='name'
                                            required={false}
                                            filterSelected
                                        />
                                    </Grid>
                                )
                                : null
                            }
                            <Grid item xs={12}>
                                <StyledDropZone
                                    error={showErrors('thumbnails')}
                                    errorText={getErrors('thumbnails')}
                                    value={formik.values.thumbnails}
                                    setValue={_.partial(formik.setFieldValue, 'thumbnails')}
                                    accept={UPLOAD_MIME_TYPES.IMAGE}
                                    maxSize={UPLOAD_MAX_FILE_SIZE.IMAGE}
                                    title={UPLOAD_TITLES.ARTICLE.THUMBNAIL}
                                />
                            </Grid>
                            {
                                formik.values.thumbnails.length > 0
                                    ? (
                                        <Grid item container wrap='wrap' xs={12}>
                                            <DraggableHorizontalList
                                                droppableId='image-list'
                                                list={formik.values.thumbnails}
                                                setList={_.partial(formik.setFieldValue, 'thumbnails')}
                                                ElementComponent={ImagePreview}
                                            />
                                        </Grid>
                                    )
                                    : null
                            }
                            <Grid item xs={12}>
                                <Editor formik={formik} fieldName='content' />
                            </Grid>
                            <Grid item xs={12}>
                                <SubmitButton label='Create' isSubmitting={formik.isSubmitting} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}
