/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TagUpdate_tag = {
    readonly id: string;
    readonly name: string;
    readonly category: string;
    readonly " $refType": "TagUpdate_tag";
};
export type TagUpdate_tag$data = TagUpdate_tag;
export type TagUpdate_tag$key = {
    readonly " $data"?: TagUpdate_tag$data;
    readonly " $fragmentRefs": FragmentRefs<"TagUpdate_tag">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "TagUpdate_tag",
  "type": "TagNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "category",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = '1c30571a51fdfe7fbb4843936e6bd1d7';
export default node;
