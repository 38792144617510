/* tslint:disable */
/* eslint-disable */
/* @relayHash b2eaeb424b4dcd5b15f2103b83536581 */

import { ConcreteRequest } from "relay-runtime";
export type SendPasswordResetMailInput = {
    email: string;
    clientMutationId?: string | null;
};
export type SendPasswordResetMutationVariables = {
    input: SendPasswordResetMailInput;
};
export type SendPasswordResetMutationResponse = {
    readonly sendPasswordResetMail: {
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        } | null> | null;
    } | null;
};
export type SendPasswordResetMutation = {
    readonly response: SendPasswordResetMutationResponse;
    readonly variables: SendPasswordResetMutationVariables;
};



/*
mutation SendPasswordResetMutation(
  $input: SendPasswordResetMailInput!
) {
  sendPasswordResetMail(input: $input) {
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SendPasswordResetMailInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "sendPasswordResetMail",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendPasswordResetMailPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SendPasswordResetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SendPasswordResetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SendPasswordResetMutation",
    "id": null,
    "text": "mutation SendPasswordResetMutation(\n  $input: SendPasswordResetMailInput!\n) {\n  sendPasswordResetMail(input: $input) {\n    errors {\n      field\n      messages\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'c3cde72584d9ea689a4737b0504a154d';
export default node;
