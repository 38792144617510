import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import { LogoutUserMutation } from 'mutations/auth/__generated__/LogoutUserMutation.graphql';

const mutation = graphql`
    mutation LogoutUserMutation {
        # revokes refresh token
        revokeToken(input: {}) {
            revoked
        }
        deleteRefreshTokenCookie(input: {}) {
            deleted
        }
        deleteTokenCookie(input: {}) {
            deleted
        }
    }
`;

function commit() {
    return commitMutation<LogoutUserMutation>(
        environment,
        {
            mutation,
            variables: {},
        }
    );
}

export default { commit };