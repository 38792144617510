import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import tawkTo from 'tawkto-react';
import theme from 'theme';
import { RelayEnvironmentProvider } from 'relay-hooks';
import environment from 'environment';
import LoadingIndicator from './common/LoadingIndicator';

interface Props {
    children: React.ReactNode;
}

export default function (props: Props) {
    const tawkPropertyId = '60cf580865b7290ac636eda8'
    const tawkKey = '1f8kuvobl'

    useEffect(() => {
        tawkTo(tawkPropertyId, tawkKey)
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <RelayEnvironmentProvider environment={environment}>
                <React.Suspense fallback={<LoadingIndicator />}>
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        {props.children}
                    </SnackbarProvider>
                </React.Suspense>
            </RelayEnvironmentProvider>
        </ThemeProvider>
    )
}
