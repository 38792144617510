import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserProtocol, queryMiddleware } from 'farce';
import { createFarceRouter, createRender, makeRouteConfig, Redirect } from 'found';
import { Resolver } from 'found-relay';
import Dashboard from 'components/dashboard/Dashboard';
import App from 'components/App';
import SignIn from 'components/views/auth/SignIn';
import SignUp from 'components/views/auth/SignUp';
import environment from 'environment';
import HomeContent from 'components/views/home/HomeContent';
import RedirectOnCondition from 'components/RedirectOnCondition';
import hasValidAuth from 'utils/hasValidAuth';
import ListRoute from 'components/ListRoute';
import { createNamedRoutesMiddleware } from 'found-named-routes';
import { URL_NAMES } from 'projectConstants';
import ArticleList from 'components/views/article/ArticleList';
import ArticleCreate from 'components/views/article/ArticleCreate';
import ArticleUpdate from 'components/views/article/ArticleUpdate';
import VideoList from 'components/views/video/VideoList';
import VideoCreate from 'components/views/video/VideoCreate';
import VideoUpdate from 'components/views/video/VideoUpdate';
import Route from 'components/Route';
import ProfileUpdate from 'components/views/profile/ProfileUpdate';
import MeQuery from 'queries/MeQuery';
import ForgotPassword from 'components/views/auth/ForgotPassword';
import ConfirmAccount from 'components/views/auth/ConfirmAccount';
import ResetPassword from 'components/views/auth/ResetPassword';
import ArticleListQuery from 'queries/article/ArticleListQuery';
import ArticleUpdateQuery from 'queries/article/ArticleUpdateQuery';
import VideoListQuery from 'queries/video/VideoListQuery';
import VideoUpdateQuery from 'queries/video/VideoUpdateQuery';
import TagList from 'components/views/tag/TagList';
import TagListQuery from 'queries/tag/TagListQuery';
import TagCreate from 'components/views/tag/TagCreate';
import TagUpdate from 'components/views/tag/TagUpdate';
import TagUpdateQuery from 'queries/tag/TagUpdateQuery';
import {default as StripeRefresh} from 'components/views/stripe/Refresh';
import {default as StripeReturn} from 'components/views/stripe/Return';


const routeConfig = makeRouteConfig(
  <Route path='/dashboard' Component={App}>
    <Route render={({ props }) => <RedirectOnCondition redirectUrl={URL_NAMES.AUTH.LOGIN} condition={() => !hasValidAuth()}  {...props} />} >
      <Route Component={Dashboard} >
        <Redirect from='/' to={URL_NAMES.HOME} />
        <Route name={URL_NAMES.HOME} path='/home' Component={HomeContent} />
        {/* profile */}
        <Route name={URL_NAMES.PROFILE} path='/profile' Component={ProfileUpdate} query={MeQuery} />
        {/* articles */}
        <ListRoute name={URL_NAMES.ARTICLE.LIST} path='/articles' Component={ArticleList} query={ArticleListQuery} />
        <Route name={URL_NAMES.ARTICLE.CREATE} path='/articles/create' Component={ArticleCreate} />
        <Route name={URL_NAMES.ARTICLE.UPDATE} path='/articles/:id' Component={ArticleUpdate} query={ArticleUpdateQuery} />
        {/* videos */}
        <ListRoute name={URL_NAMES.VIDEO.LIST} path='/videos' Component={VideoList} query={VideoListQuery} />
        <Route name={URL_NAMES.VIDEO.CREATE} path='/videos/create' Component={VideoCreate} />
        <Route name={URL_NAMES.VIDEO.UPDATE} path='/videos/:id' Component={VideoUpdate} query={VideoUpdateQuery} />
        {/* tags */}
        <ListRoute name={URL_NAMES.TAG.LIST} path='/tags' Component={TagList} query={TagListQuery} />
        <Route name={URL_NAMES.TAG.CREATE} path='/tags/create' Component={TagCreate} />
        <Route name={URL_NAMES.TAG.UPDATE} path='/tags/:id' Component={TagUpdate} query={TagUpdateQuery} />
        {/* stripe */}
        <Route name={URL_NAMES.STRIPE.RETURN} path='/stripe/connect/return' Component={StripeReturn} />
        <Route name={URL_NAMES.STRIPE.REFRESH} path='/stripe/connect/refresh' Component={StripeRefresh} />
      </Route>
    </Route>
    <Route render={({ props }) => <RedirectOnCondition redirectUrl={URL_NAMES.HOME} condition={hasValidAuth}  {...props} />} >
      <Route name={URL_NAMES.AUTH.LOGIN} path='/login' Component={SignIn} />
      <Route name={URL_NAMES.AUTH.REGISTER} path='/register' Component={SignUp} />
      <Route name={URL_NAMES.AUTH.FORGOT} path='/forgot' Component={ForgotPassword} />
      <Route name={URL_NAMES.AUTH.RESET} path='/reset/:token' Component={ResetPassword} />
    </Route>
    {/* confirm should be without redirect as it works with and without auth */}
    <Route name={URL_NAMES.AUTH.CONFIRM} path='/confirm/:token' Component={ConfirmAccount} />
  </Route >
);

const namedRoutesMiddleware = createNamedRoutesMiddleware(routeConfig);

const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [namedRoutesMiddleware, queryMiddleware],
  routeConfig,

  render: createRender({}),
});

ReactDOM.render(
  <Router ref={(router: Element) => (window as any).router = router} resolver={new Resolver(environment)} />,
  document.querySelector('#root')
);
