/* tslint:disable */
/* eslint-disable */
/* @relayHash 7ddfa908d0a3ecf8725386503bdcbfbc */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TagListQueryVariables = {
    first?: number | null;
    last?: number | null;
    before?: string | null;
    after?: string | null;
    orderBy?: string | null;
};
export type TagListQueryResponse = {
    readonly allTags: {
        readonly " $fragmentRefs": FragmentRefs<"TagList_allTags">;
    } | null;
};
export type TagListQuery = {
    readonly response: TagListQueryResponse;
    readonly variables: TagListQueryVariables;
};



/*
query TagListQuery(
  $first: Int
  $last: Int
  $before: String
  $after: String
  $orderBy: String
) {
  allTags(first: $first, last: $last, before: $before, after: $after, orderBy: $orderBy) {
    ...TagList_allTags
  }
}

fragment TagList_allTags on TagNodeConnection {
  totalCount
  edges {
    node {
      id
      name
      category
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "last",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "before",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TagListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allTags",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TagNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TagList_allTags",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TagListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allTags",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TagNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "TagNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "TagNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "category",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "TagListQuery",
    "id": null,
    "text": "query TagListQuery(\n  $first: Int\n  $last: Int\n  $before: String\n  $after: String\n  $orderBy: String\n) {\n  allTags(first: $first, last: $last, before: $before, after: $after, orderBy: $orderBy) {\n    ...TagList_allTags\n  }\n}\n\nfragment TagList_allTags on TagNodeConnection {\n  totalCount\n  edges {\n    node {\n      id\n      name\n      category\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'e71f7383f267c82f26b9c88d08a6fee3';
export default node;
