import React from 'react';
import { Link as FoundLink, useRouter } from 'found';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {
    makeStyles,
    Theme,
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import RegisterUserMutation from "mutations/user/RegisterUserMutation";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormikInputField from 'components/fields/FormikInputField';
import SubmitButton from 'components/fields/SubmitButton';
import LoginUserMutation from 'mutations/auth/LoginUserMutation';
import setTokenExp from 'utils/setTokenExp';
import { URL_NAMES, YUP_MESSAGES } from 'projectConstants';
import Copyright from 'components/common/Copyright';
import { transformToFormikErrors } from 'utils/formikUtils';
import GlobalErrorField from 'components/fields/GlobalErrorField';
import FormikPasswordField from 'components/fields/FormikPasswordField';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignUp() {
    const classes = useStyles();
    const { router } = useRouter();
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            password: '',
        },
        onSubmit: async (values, { setStatus }) => {
            const response = await RegisterUserMutation.commit(
                values.email, values.password, values.firstName, values.lastName, values.username
            );
            if (response?.createUpdateUser?.errors) {
                setStatus(transformToFormikErrors(response.createUpdateUser));
            } else {
                setStatus({});
                const loginResponse = await LoginUserMutation.commit(
                    values.email, values.password
                );
                setTokenExp(loginResponse);
                router.push(URL_NAMES.HOME);
            }
        },
        validationSchema:
            Yup.object().shape({
                firstName: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
                lastName: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
                username: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
                email: Yup.string()
                    .email(YUP_MESSAGES.EMAIL)
                    .required(YUP_MESSAGES.REQUIRED),
                password: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
            })
    });

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <GlobalErrorField formik={formik} />
                        <Grid item xs={12} sm={6}>
                            <FormikInputField
                                Component={TextField}
                                formik={formik}
                                fieldName='firstName'
                                label='First Name'
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikInputField
                                Component={TextField}
                                formik={formik}
                                fieldName='lastName'
                                label='Last Name'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikInputField
                                Component={TextField}
                                formik={formik}
                                fieldName='username'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikInputField
                                Component={TextField}
                                formik={formik}
                                fieldName='email'
                                label="Email Address"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikPasswordField
                                formik={formik}
                                fieldName='password'
                            />
                        </Grid>
                    </Grid>
                    <SubmitButton label='Sign Up' className={classes.submit} fullWidth />
                    <Grid container justify="flex-end">
                        <Grid item>
                            <FoundLink as={Link} to={URL_NAMES.AUTH.LOGIN} variant='body2'>
                                Already have an account? Sign in
                            </FoundLink>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}

