/* tslint:disable */
/* eslint-disable */
/* @relayHash 4450eff8c1fa1c78cf271e3cccdb5b3a */

import { ConcreteRequest } from "relay-runtime";
export type PostCreateUpdateInput = {
    tags?: Array<string | null> | null;
    id?: string | null;
    title: string;
    calories?: number | null;
    category?: string | null;
    content?: string | null;
    postType: string;
    thumbnail?: string | null;
    video?: string | null;
    clientMutationId?: string | null;
};
export type PostCreateUpdateMutationVariables = {
    input: PostCreateUpdateInput;
};
export type PostCreateUpdateMutationResponse = {
    readonly createUpdatePost: {
        readonly id: string | null;
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        } | null> | null;
    } | null;
};
export type PostCreateUpdateMutation = {
    readonly response: PostCreateUpdateMutationResponse;
    readonly variables: PostCreateUpdateMutationVariables;
};



/*
mutation PostCreateUpdateMutation(
  $input: PostCreateUpdateInput!
) {
  createUpdatePost(input: $input) {
    id
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "PostCreateUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createUpdatePost",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PostCreateUpdatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PostCreateUpdateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PostCreateUpdateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "PostCreateUpdateMutation",
    "id": null,
    "text": "mutation PostCreateUpdateMutation(\n  $input: PostCreateUpdateInput!\n) {\n  createUpdatePost(input: $input) {\n    id\n    errors {\n      field\n      messages\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'fdedb83a204e6338cf59d07055acac75';
export default node;
