import { useEffect } from 'react';
import { useRouter } from 'found';
import { URL_NAMES } from 'projectConstants';

export default function () {
    const { router } = useRouter();
    useEffect(() => {
        router.push(URL_NAMES.PROFILE);
    }, [router])
    return null;
}