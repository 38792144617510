/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ArticleList_allPosts = {
    readonly totalCount: number | null;
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly id: string;
            readonly title: string;
            readonly category: string;
        } | null;
    } | null>;
    readonly " $refType": "ArticleList_allPosts";
};
export type ArticleList_allPosts$data = ArticleList_allPosts;
export type ArticleList_allPosts$key = {
    readonly " $data"?: ArticleList_allPosts$data;
    readonly " $fragmentRefs": FragmentRefs<"ArticleList_allPosts">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "ArticleList_allPosts",
  "type": "PostNodeConnection",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "PostNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "PostNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "category",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = '0886e2b3f5bd781a4a960b5f0448e241';
export default node;
