import jwtDecode from 'jwt-decode';
import {REFRESH_EXP_KEY, TOKEN_EXP_KEY} from 'projectConstants';
import {RefreshTokenMutationResponse} from 'mutations/auth/__generated__/RefreshTokenMutation.graphql';
import {LoginUserMutationResponse} from 'mutations/auth/__generated__/LoginUserMutation.graphql';

export default function (response: LoginUserMutationResponse | RefreshTokenMutationResponse) {
    let tokenResponse;
    if (response.hasOwnProperty('tokenAuth')) {
        tokenResponse = (response as LoginUserMutationResponse).tokenAuth;
    } else if (response.hasOwnProperty('refreshToken')) {
        tokenResponse = (response as RefreshTokenMutationResponse).refreshToken;
    }
    if (!tokenResponse) return;
    const token = tokenResponse.token;
    const refreshExpiresIn = tokenResponse.refreshExpiresIn;
    if (token === null || refreshExpiresIn === null) return;
    const decodedToken = jwtDecode(token) as { [key: string]: string };
    localStorage.setItem(TOKEN_EXP_KEY, decodedToken.exp.toString());
    localStorage.setItem(REFRESH_EXP_KEY, refreshExpiresIn.toString());
}