import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import TextField from '@material-ui/core/TextField/TextField';
import { FormikInstance, useFormikErrors } from 'utils/formikUtils';

interface Props {
    fieldName: string;
    formik: FormikInstance;
    options: any[];
    optionLabelFieldName: string;
    filterSelected?: boolean;
    disableTags?: boolean;
    [key: string]: any;
}

export default function ({fieldName, formik, options, optionLabelFieldName, filterSelected, disableTags, ...props}: Props) {
    const [showErrors, getErrors] = useFormikErrors(formik);

    if (filterSelected) {
        const selectedIds = formik.values[fieldName].map((value: any) => value.id);
        options = options.filter(value => !selectedIds.includes(value.id));
    }
    
    return (
        <Autocomplete
            multiple
            id={fieldName}
            options={options}
            getOptionLabel={(option: {[key: string]: any}) => option[optionLabelFieldName]}
            onChange={(_, value: any) => formik.setFieldValue(fieldName, value)}
            onBlur={formik.handleBlur(fieldName)}
            filterSelectedOptions={filterSelected}
            value={formik.values[fieldName]}
            disableClearable={disableTags}
            {...(disableTags ? {renderTags: () => null} : {})}
            renderInput={(params: any) => (
                <TextField
                    {...params}
                    variant="outlined"
                    onChange={(event) => formik.setFieldValue(`${fieldName}TextField`, event.target.value)}
                    label={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                    error={showErrors(fieldName)}
                    helperText={getErrors(fieldName)}
                    required
                    fullWidth
                    {...props}
                />
            )}
        />
    );
}