/* tslint:disable */
/* eslint-disable */
/* @relayHash bba8ed98ed44e86ad0eb7bf708949390 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TagUpdateQueryVariables = {
    id: string;
};
export type TagUpdateQueryResponse = {
    readonly tag: {
        readonly " $fragmentRefs": FragmentRefs<"TagUpdate_tag">;
    } | null;
};
export type TagUpdateQuery = {
    readonly response: TagUpdateQueryResponse;
    readonly variables: TagUpdateQueryVariables;
};



/*
query TagUpdateQuery(
  $id: ID!
) {
  tag(id: $id) {
    ...TagUpdate_tag
    id
  }
}

fragment TagUpdate_tag on TagNode {
  id
  name
  category
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TagUpdateQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tag",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TagNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TagUpdate_tag",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TagUpdateQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tag",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TagNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "category",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "TagUpdateQuery",
    "id": null,
    "text": "query TagUpdateQuery(\n  $id: ID!\n) {\n  tag(id: $id) {\n    ...TagUpdate_tag\n    id\n  }\n}\n\nfragment TagUpdate_tag on TagNode {\n  id\n  name\n  category\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'd3bc30c9ca1d9f86bc99cfd32dfdb2a7';
export default node;
