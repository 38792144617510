/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserCurrency = "USD" | "%future added value";
export type ProfileUpdate_me = {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly username: string | null;
    readonly status: {
        readonly verified: boolean;
    } | null;
    readonly stripeEnabled: boolean;
    readonly currency: UserCurrency | null;
    readonly amount: number | null;
    readonly creatorProfile: {
        readonly id: string;
        readonly bio: string | null;
        readonly profileImage: {
            readonly id: string;
            readonly image: unknown | null;
        } | null;
    } | null;
    readonly " $refType": "ProfileUpdate_me";
};
export type ProfileUpdate_me$data = ProfileUpdate_me;
export type ProfileUpdate_me$key = {
    readonly " $data"?: ProfileUpdate_me$data;
    readonly " $fragmentRefs": FragmentRefs<"ProfileUpdate_me">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ProfileUpdate_me",
  "type": "UserNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "status",
      "storageKey": null,
      "args": null,
      "concreteType": "UserStatusNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "verified",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "stripeEnabled",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "currency",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "amount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "creatorProfile",
      "storageKey": null,
      "args": null,
      "concreteType": "CreatorProfileNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "bio",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "profileImage",
          "storageKey": null,
          "args": null,
          "concreteType": "ImageNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "image",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = '702219837df012b71fdee7c88112c8de';
export default node;
