import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import {RegisterUserMutation} from 'mutations/user/__generated__/RegisterUserMutation.graphql';

const mutation = graphql`
    mutation RegisterUserMutation($input: UserCreateUpdateInput!) {
        createUpdateUser(input: $input) {
            id,
            errors {
                field,
                messages
            }
        }
    }
`;

function commit(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    username: string,
) {
    return commitMutation<RegisterUserMutation>(
        environment,
        {
            mutation,
            variables: {
                input: {
                    password: password,
                    role: 'cr',
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    username,
                },
            },
        }
    );
}

export default {commit};