import commitMutation from 'relay-commit-mutation-promise';
import graphql from 'babel-plugin-relay/macro';
import environment from 'environment';
import { ResetPasswordMutation } from 'mutations/auth/__generated__/ResetPasswordMutation.graphql';

const mutation = graphql`
    mutation ResetPasswordMutation($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            errors {
                field,
                messages
            }
        }
    }
`;

function commit(
    emailToken: string,
    newPassword: string
) {
    return commitMutation<ResetPasswordMutation>(
        environment,
        {
            mutation,
            variables: {
                input: {
                    emailToken,
                    newPassword
                },
            },
        }
    );
}

export default {commit};