import React from 'react';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { useRouter } from 'found';
import removeTokenExp from 'utils/removeTokenExp';
import { URL_NAMES } from 'projectConstants';
import LogoutUserMutation from 'mutations/auth/LogoutUserMutation';

interface ProfileMenuProps {
    anchorElement: Element | null;
    open: boolean;
    setOpen: (arg0: boolean) => void;
}

export default function ({ anchorElement, open, setOpen }: ProfileMenuProps) {
    const { router } = useRouter();

    return (
        <Menu
            anchorEl={anchorElement}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={() => setOpen(false)}
            keepMounted
        >
            <MenuItem onClick={async () => {
                setOpen(false);
                router.push(URL_NAMES.PROFILE);
            }}>
                Profile
            </MenuItem>
            <MenuItem onClick={async () => {
                setOpen(false);
                await LogoutUserMutation.commit()
                removeTokenExp();
                router.push(URL_NAMES.AUTH.LOGIN);
            }}>
                Logout
            </MenuItem>
        </Menu>
    );
}