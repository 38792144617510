import { useSnackbar } from 'notistack';
import { useRouter } from 'found';
import { URL_NAMES } from 'projectConstants';
import ConfirmAccountMutation from "mutations/auth/ConfirmAccountMutation";
import { useEffect } from 'react';

export default function () {
    const { enqueueSnackbar } = useSnackbar();
    const { router, match } = useRouter();
    const token = match.params.token;

    if (token) {
        ConfirmAccountMutation.commit(token)
            .then(response => {
                if (response?.verifyEmail?.errors) {
                    const errorMessages = response.verifyEmail.errors;
                    errorMessages.forEach(errorObject => {
                        if (errorObject) enqueueSnackbar(errorObject.messages[0], { variant: 'error' });
                    });

                } else {
                    enqueueSnackbar('Account confirmed successfully', { variant: 'success' });
                }
            })
    }

    useEffect(() => {
        router.push(URL_NAMES.HOME);
    })
    return null;
}
