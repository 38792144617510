import React from 'react';
import Dialog from 'components/common/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';

interface DeleteDialogProps {
    open: boolean;
    title?: string;
    bodyText?: string;
    handleClose: (confirmed: boolean) => void;
}

export default function (props: DeleteDialogProps) {
    const { open, title, bodyText, handleClose } = props;

    const content = (
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {bodyText || 'Are you sure you want to delete the selected item/items?'}
            </DialogContentText>
        </DialogContent>
    );

    const actions = (
        <DialogActions>
            <Button onClick={() => handleClose(false)} color="primary" startIcon={<CancelIcon />}>
                Cancel
            </Button>
            <Button onClick={() => handleClose(true)} color="secondary" startIcon={<DeleteIcon />}>
                Delete
            </Button>
        </DialogActions>
    );

    return <Dialog
        open={open}
        title={title || 'Confirmation'}
        content={content}
        actions={actions}
        handleClose={() => handleClose(false)}
    />
}