/* tslint:disable */
/* eslint-disable */
/* @relayHash 6327c4121e5b09e03d4771fbf700d6bb */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ArticleListQueryVariables = {
    first?: number | null;
    last?: number | null;
    before?: string | null;
    after?: string | null;
    orderBy?: string | null;
};
export type ArticleListQueryResponse = {
    readonly allPosts: {
        readonly " $fragmentRefs": FragmentRefs<"ArticleList_allPosts">;
    } | null;
};
export type ArticleListQuery = {
    readonly response: ArticleListQueryResponse;
    readonly variables: ArticleListQueryVariables;
};



/*
query ArticleListQuery(
  $first: Int
  $last: Int
  $before: String
  $after: String
  $orderBy: String
) {
  allPosts(first: $first, last: $last, before: $before, after: $after, orderBy: $orderBy, postType: "article") {
    ...ArticleList_allPosts
  }
}

fragment ArticleList_allPosts on PostNodeConnection {
  totalCount
  edges {
    node {
      id
      title
      category
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "last",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "before",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Literal",
    "name": "postType",
    "value": "article"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ArticleListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allPosts",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PostNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ArticleList_allPosts",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ArticleListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allPosts",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PostNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "PostNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "PostNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "category",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ArticleListQuery",
    "id": null,
    "text": "query ArticleListQuery(\n  $first: Int\n  $last: Int\n  $before: String\n  $after: String\n  $orderBy: String\n) {\n  allPosts(first: $first, last: $last, before: $before, after: $after, orderBy: $orderBy, postType: \"article\") {\n    ...ArticleList_allPosts\n  }\n}\n\nfragment ArticleList_allPosts on PostNodeConnection {\n  totalCount\n  edges {\n    node {\n      id\n      title\n      category\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'c137d2d512ebf60f3bd21b7a9320087a';
export default node;
