import React, { useState } from 'react';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import Grid, { GridSize } from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { transformToFormikErrors, useFormikErrors } from 'utils/formikUtils';
import GlobalErrorField from 'components/fields/GlobalErrorField';
import PostCreateUpdateMutation from 'mutations/post/PostCreateUpdateMutation';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import SelectField from 'components/fields/SelectField';
import { useRouter } from 'found';
import FormikInputField from 'components/fields/FormikInputField';
import SubmitButton from 'components/fields/SubmitButton';
import graphql from 'babel-plugin-relay/macro';
import { useFragment, useLazyLoadQuery } from 'relay-hooks';
import { VideoUpdate_post$key } from 'components/views/video/__generated__/VideoUpdate_post.graphql';
import { UPLOAD_MAX_FILE_SIZE, UPLOAD_MIME_TYPES, UPLOAD_TITLES, URL_NAMES, YUP_MESSAGES, } from 'projectConstants';
import IntegerFormikInputField from 'components/fields/IntegerFormikInputField';
import ImageDeleteMutation from 'mutations/image/ImageDeleteMutation';
import { imageUpdate, videoUpdate } from 'utils/upload';
import StyledDropZone from 'components/common/StyledDropZone';
import DraggableHorizontalList from 'components/common/DraggableHorizontalList';
import ImagePreview from 'components/common/ImagePreview';
import VideoPreview from 'components/common/VideoPreview';
import VideoDeleteMutation from 'mutations/video/VideoDeleteMutation';
import { conversionStatusToDisplay, generatePillColor } from './utils';
import Chip from 'components/common/Chip';
import TagAutoCompleteQuery from 'queries/tag/TagAutoCompleteQuery';
import { TagAutoCompleteQuery as TagAutoCompleteQueryType } from 'queries/tag/__generated__/TagAutoCompleteQuery.graphql';
import AutoComplete from 'components/fields/AutoComplete';

const fragmentSpec = graphql`
    fragment VideoUpdate_post on PostNode {
        id,
        title,
        calories,
        category,
        content,
        postType,
        thumbnail {
            id
            image
        }
        video {
            id
            uploadedVideo
            conversionStatus
        },
        tags {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

const fieldBreakPoints: { [key: string]: GridSize } = { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 };

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
}));

interface VideoUpdateProps {
    post: VideoUpdate_post$key;
}

export default function (props: VideoUpdateProps) {
    const classes = useStyles();
    const [deletedImages, setDeletedImages] = useState<any[]>([]);
    const [deletedVideos, setDeletedVideos] = useState<any[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const post = useFragment(fragmentSpec, props.post);
    const { router } = useRouter();
    const [conversionStatus] = useState<undefined | string>(post.video?.conversionStatus)
    let thumbnails: {[key: string]: any}[] = [];
    if (post.thumbnail) {
        thumbnails = [{ id: post.thumbnail.id, order: 0, preview: post.thumbnail.image }]
    }
    let videos: {[key: string]: any}[] = [];
    if (post.video) {
        videos = [{ id: post.video.id, order: 0, preview: post.video.uploadedVideo }]
    }
    const formik = useFormik({
        initialValues: {
            title: post.title,
            calories: post.calories,
            category: post.category,
            tags: post.tags.edges.map(edge => edge?.node),
            tagsTextField: '',
            thumbnails: thumbnails,
            videos: videos,
            content: post.content,
        },
        onSubmit: async (values, { setStatus }) => {
            let imageId = undefined;
            if (deletedImages.length > 0) {
                await ImageDeleteMutation.commit([deletedImages[0].id])
            }
            if (values.thumbnails.length > 0) {
                imageId = await imageUpdate(values.thumbnails[0]);
            }
            let videoId = undefined;
            if (deletedVideos.length > 0) {
                await VideoDeleteMutation.commit([deletedVideos[0].id])
            }
            if (values.videos.length > 0) {
                videoId = await videoUpdate(values.videos[0]);
            }
            const response = await PostCreateUpdateMutation.commit(
                {
                    title: values.title,
                    calories: values.calories,
                    category: values.category,
                    tags: values.tags.filter((tag): tag is any => typeof tag === "object").map((tag: {id: string}) => tag.id),
                    content: values.content,
                    postType: post.postType,
                    thumbnail: imageId,
                    video: videoId,
                    id: post.id
                }
            );
            if (response?.createUpdatePost?.errors) {
                setStatus(transformToFormikErrors(response.createUpdatePost));
            } else {
                setStatus({});
                enqueueSnackbar('Successfully updated', { variant: 'success' });
                router.push(URL_NAMES.VIDEO.LIST);
            }
        },
        validationSchema:
            Yup.object().shape({
                title: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
                calories: Yup.number()
                    .integer(YUP_MESSAGES.INTEGER)
                    .min(0, YUP_MESSAGES.NO_NEGATIVE)
                    .required(YUP_MESSAGES.REQUIRED),
                category: Yup.string()
                    .required(YUP_MESSAGES.REQUIRED),
                tags: Yup.array(),
                thumbnails: Yup.array(),
                videos: Yup.array()
                    .required(YUP_MESSAGES.REQUIRED),
                content: Yup.string(),
            })
    });

    const setValueThumbnail = (value: {[key: string]: any}) => {
        if (formik.values.thumbnails.length > 0 && formik.values.thumbnails[0].id !== undefined) {
            setDeletedImages([formik.values.thumbnails[0]]);
        }
        formik.setFieldValue('thumbnails', value)
    }

    const setValueVideo = (value: {[key: string]: any}) => {
        if (formik.values.videos.length > 0 && formik.values.videos[0].id !== undefined) {
            setDeletedVideos([formik.values.videos[0]]);
        }
        formik.setFieldValue('videos', value)
    }

    const data = useLazyLoadQuery<TagAutoCompleteQueryType>(
        TagAutoCompleteQuery,
        {name_Contains: formik.values.tagsTextField, category: formik.values.category},
    )

    const [showErrors, getErrors] = useFormikErrors(formik);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                        <Grid container item spacing={2} {...fieldBreakPoints} direction='column'>
                            <Grid item xs={12}>
                                <Typography variant='h5'>Update Video</Typography>
                            </Grid>
                            <GlobalErrorField formik={formik} Parent={Grid} item xs={12} />
                            <Grid item xs={12}>
                                <FormikInputField
                                    Component={TextField}
                                    formik={formik}
                                    fieldName='title'
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <IntegerFormikInputField
                                    formik={formik}
                                    fieldName='calories'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikInputField
                                    Component={SelectField}
                                    formik={formik}
                                    fieldName='category'
                                    choices={{ 'workout': 'Workout', 'nutrition': 'Meal', 'challenge': 'Challenge' }}
                                />
                            </Grid>
                            {
                                formik.values.category
                                ? (
                                    <Grid item xs={12}>
                                        <AutoComplete
                                            formik={formik}
                                            fieldName='tags'
                                            options={_.map(data.props?.tags?.edges, edge => edge?.node)}
                                            optionLabelFieldName='name'
                                            required={false}
                                            filterSelected
                                        />
                                    </Grid>
                                )
                                : null
                            }
                            {
                                conversionStatus ?
                                <>
                                    <Grid item xs={12}>
                                        Conversion status:
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chip color={generatePillColor(conversionStatus)} label={conversionStatusToDisplay(conversionStatus)} />
                                    </Grid>
                                </>
                                : null
                            }
                            <Grid item xs={12}>
                                <StyledDropZone
                                    error={showErrors('thumbnails')}
                                    errorText={getErrors('thumbnails')}
                                    value={formik.values.thumbnails}
                                    setValue={setValueThumbnail}
                                    accept={UPLOAD_MIME_TYPES.IMAGE}
                                    maxSize={UPLOAD_MAX_FILE_SIZE.IMAGE}
                                    title={UPLOAD_TITLES.VIDEO.THUMBNAIL}
                                />
                            </Grid>
                            {
                                formik.values.thumbnails.length > 0
                                    ? (
                                        <Grid item container wrap='wrap' xs={12}>
                                            <DraggableHorizontalList
                                                droppableId='image-list'
                                                list={formik.values.thumbnails}
                                                setList={setValueThumbnail}
                                                deletedElements={deletedImages}
                                                setDeletedElements={setDeletedImages}
                                                ElementComponent={ImagePreview}
                                            />
                                        </Grid>
                                    )
                                    : null
                            }
                            <Grid item xs={12}>
                                <StyledDropZone
                                    error={showErrors('videos')}
                                    errorText={getErrors('videos')}
                                    value={formik.values.videos}
                                    setValue={setValueVideo}
                                    accept={UPLOAD_MIME_TYPES.VIDEO}
                                    maxSize={UPLOAD_MAX_FILE_SIZE.VIDEO}
                                    title={UPLOAD_TITLES.VIDEO.UPLOAD}
                                />
                            </Grid>
                            {
                                formik.values.videos.length > 0
                                    ? (
                                        <Grid item container wrap='wrap' xs={12}>
                                            <DraggableHorizontalList
                                                droppableId='video-list'
                                                list={formik.values.videos}
                                                setList={setValueVideo}
                                                deletedElements={deletedVideos}
                                                setDeletedElements={setDeletedVideos}
                                                ElementComponent={VideoPreview}
                                            />
                                        </Grid>
                                    )
                                    : null
                            }
                            <Grid item xs={12}>
                                <SubmitButton label='Save' isSubmitting={formik.isSubmitting} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}
